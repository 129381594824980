import Swal from 'sweetalert2';

export const subscriberSuccess = async () => {
  await Swal.fire({
    title: 'Thank you for subscribing!',
    timer: 5000,
    icon: 'success',
  }).then(() => {
    window.location.reload();
  });
};

export const subscriberFail = async () => {
    await Swal.fire({
      title: 'Error: Could not subscribe',
      text: "Sorry, but we could not process your request. Please try again later.",
      timer: 5000,
      icon: 'error',
    }).then(() => {
      window.location.reload();
    });
  };