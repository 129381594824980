import React, { useState } from 'react'
import axios from 'axios'

export default function Login() {

    const [user, setUser] = useState({
        username: '',
        password: '',
    })

    const handleChange = (e) => {
        e.preventDefault();
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const handleClick = (e) => {
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_BASE_URL}/auth/login`, { username: user.username, password: user.password })
            .then(res => {
                if (res.status !== 200) {
                    window.location = '/unauthorized'
                } else if (res.status === 200) {
                    window.localStorage.setItem('auth', res.data.token)
                    window.alert('Login Successful. You may close this window to be redirected.')
                    window.location = '/dashboard'
                }
            }).catch(function (error) {
                if (error.response) {
                    window.alert(`${error.response.data.message}`)
                }
            }
            )
    }

    return (
        <div
            style={{
                width: '60%',
            }}
        >
            <h1>Login</h1>
            <div className='loginForm'
                style={{
                    width: '100%',
                }}
            >
                <form>
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id='username'
                        placeholder='Enter Username'
                        name='username'
                        value={user.username}
                        style={{
                            width: '50%'
                        }}
                        onChange={handleChange}
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id='password'
                        placeholder='Enter Password'
                        name='password'
                        value={user.password}
                        style={{
                            width: '50%'
                        }}
                        onChange={handleChange}
                    />
                    <button
                        onClick={handleClick}
                        style={{
                            margin: 'auto',
                            marginTop: '2%',
                            marginBottom: '2%'
                        }}
                    >Login</button>
                    <a href="/reset-password">Reset Password</a>
                </form>
            </div>
        </div>
    )
}
