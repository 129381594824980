import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function BlogData() {
  const [data, setData] = useState([]);

  console.log('url path: ', window.origin);

  const BlogPage = window.origin + '/post/';

  const cleanTitle = (data) => {
    let checkTitle = data.title.replace('amp;', '');
    return checkTitle;
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blog/posts`)
      .then((res) => {
        setData(res.data.object);
      });
  }, []);

  const sortedData = data.sort(
    (a, b) => b.publishedAt - a.publishedAt
  );

  return (
    <div>
      <div
        style={{
          marginTop: '5%',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {sortedData.map((post) => {
          return (
            <div
              className="blog"
              key={post.id}
              style={{
                width: '40%',
                margin: 'auto',
                marginBottom: '2%',
                border: '#064789 solid 2px',
                padding: '2%',
              }}
            >
              <a
                href={BlogPage + `${post.title}`}
                style={{
                  textDecoration: 'none',
                  color: 'black',
                  margin: 'auto',
                }}
                name={post.name}
                value={post.id}
              >
                <h1
                  style={{
                    margin: 'auto',
                    marginBottom: '2%',
                  }}
                >
                  {cleanTitle(post)}
                </h1>
                <p>{post.summary}</p>
              </a>
            </div>
          );
        })}
      </div>

      {/* <div style={{marginTop: '5%'}}>
                {sortedData.map(post => {
                    return (
                        <div 
                            className='blog' 
                            key={post.id}
                            style={{
                                width: '70%',
                                margin: 'auto',
                                marginBottom: '2%',
                                borderBottom: '#064789 solid 2px'
                            }}
                        >
                            <h1
                                style={{
                                    margin: "auto",
                                    marginBottom: '2%',
                                }}    
                            >
                                {cleanTitle(post)}
                            </h1>
                            <div 
                                dangerouslySetInnerHTML={{__html: post.content}} 
                                style={{
                                    marginTop: "2%",
                                    marginBottom: '2%',
                                    fontSize: '18px'
                                }}
                            />
                        </div>
                    )
                })}
            </div> */}
    </div>
  );
}
