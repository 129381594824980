import React from "react";

export default function Terms() {
  return (
    <div className="terms">
      <div className="wrapper">
        <h1>Terms and Conditions</h1>
        <h3>Updated on: 11/23/2021</h3>
        <h2>1. Agreement to Terms</h2>
        <p>
          These Terms and Conditions constitute a legally binding agreement made
          between you, whether personally or on behalf of an entity ('you') and
          Cake Nutrition, LLC (<strong>"Company," "we," "us," "or"),</strong>)
          concerning your access to and use of the website as well as any other
          media form, channe, mobile website or mobile application related,
          linked, or otherwise connected therto (collectively the "Site"). You
          agree by accessing this Site, you have ready, understand, and agreed
          under no form of duress to be bound by these Terms and Conditions in
          all of their entirety.
          <br />
          <br />
          The information provided on the Site is not intended for distribution
          to or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. Accordingly, those persons who choose to
          access the Site from other locations do so on their own initiative and
          are solely responsible for compliance with local laws, if and to the
          extent local laws are applicable.
          <br />
          <br />
          This website follows HIPAA Compliancy Requirements for websites that
          may take in sensitive patient information. Please see our HIPAA Policy
          page for more information.
          <br />
          <br />
          The Site is intended for users who are at least 18 years old. Persons
          under the age of 18 are not permitted to use or register for the Site.
        </p>
        <div className="underline"></div>
        <h2>2. Intellectual and Property Rights</h2>
        <p>
          Unless otherwise indicated, this website is our property and all
          source code, databases, functionality, software, website designs,
          audio, text, video, photographs, and graphics on the site
          (collectively, the "Content") and the trademarks, service marks, and
          logos contained therein (the "Marks") are owned or controlled by us or
          licensed to us, and are protected by copyright and trademark laws and
          various other intellectual property rights and unfair competition laws
          of the United States, international copyright laws, and international
          conventions. The Content and Marks are provided on the site 'AS IS'
          for your information and personal use only. Except as expressly
          provided in these Terms, no part of the Site, Content, or Marks may be
          copied, reproduced, aggregated, republished, uploaded, posted,
          publically displayed, encoded, translated, transmitted, distributed,
          sold, licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express written permission.
          <br />
          <br />
          Provided that you are eligible to use the Site, you are granted a
          limited license to access and use the Site and to download or print a
          copy of any portion of the Content to which you have properly gained
          access solely for your personal, non-commercial use. We reserve all
          rights not expressly granted to you in and to the Site, the Content
          and the Marks.
        </p>
        <div className="underline"></div>
        <h2>3. User Representation </h2>
        <p>
          By accessing this web site, you are agreeing to be bound by these web
          site Terms and Conditions of Use, all applicable laws and regulations,
          and agree that you are responsible for compliance with any applicable
          local laws. If you do not agree with any of these terms, you are
          prohibited from using or accessing this site. The materials contained
          in this web site are protected by applicable copyright and trade mark
          law.
          <br />
          <br />
          By using the Site, you represent and warrant that: (1) you have the
          legal capacity and you agree to comply with these Terms; (2) you are
          not a minor in the jusrisdiction in which you reside; (3) you will not
          access the Site through automated or non-human operated means, whether
          thorugh a bot, script, or otherwise; (4) you will not use the Site for
          any illegal or unauthorized purpose, both within the jurisdiction you
          reside and with regards to International Internet Law.
          <br />
          <br />
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Site (or
          any portion thereof).
        </p>
        <div className="underline"></div>
        <h2>4. Prohibited Activities</h2>
        <p>
          You may not access or use the Site for any purpose other than that for
          which we make the Site available. The Site may not be used in
          connection with any commercial endeavors except those that are
          specifically endorsed or approved by us.
          <br />
          <br />
          As a user of the Site, you aggree not to:
        </p>
        <ul className='unorderedList'>
          <li>
            Systematically retrieve data or other content from the Site to
            create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            Trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords.
          </li>
          <li>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Site, including features that prevent or restrict
            the use or copying of any Content or enforce limitations on the use
            of the Site and/or the Content contained therein.
          </li>
          <li>
            Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            Site.
          </li>
          <li>
            Use any information obtained from the Site in order to harass,
            abuse, or harm another person.
          </li>
          <li>
            Make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>
          <li>
            Use the Site in a manner inconsistent with any applicable laws or
            regulations.
          </li>
          <li>Engage in unauthorized framing of or linking to the Site.</li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            Site or modifies, impairs, disrupts, alters, or interferes with the
            use, features, functions, operation, or maintenance of the Site.
          </li>
          <li>
            Engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            Delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            Attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>
            Upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </li>
          <li>
            Interfere with, disrupt, or create an undue burden on the Site or
            the networks or services connected to the Site.
          </li>
          <li>
            Harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the Site to you.
          </li>
          <li>
            Attempt to bypass any measures of the Site designed to prevent or
            restrict access to the Site, or any portion of the Site.
          </li>
          <li>
            Copy or adapt the Site’s software, including but not limited to
            Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            Except as permitted by applicable law, decipher, decompile,
            disassemble, or reverse engineer any of the software comprising or
            in any way making up a part of the Site.
          </li>
          <li>
            Except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the Site, or using
            or launching any unauthorized script or other software.
          </li>
          <li>
            Use a buying agent or purchasing agent to make purchases on the
            Site.
          </li>
          <li>
            Make any unauthorized use of the Site, including collecting
            usernames and/or email addresses of users by electronic or other
            means for the purpose of sending unsolicited email, or creating user
            accounts by automated means or under false pretenses.
          </li>
          <li>
            Use the Site as part of any effort to compete with us or otherwise
            use the Site and/or the Content for any revenue-generating endeavor
            or commercial enterprise.
          </li>
          <li>
            Use the Site to advertise or offer to sell goods and services.
          </li>
          <li>Sell or otherwise transfer your profile.</li>
        </ul>
        <div className="underline"></div>
        <h2>5. User Generated Contributions</h2>
        <p>
          The Site does not offer users to submit or post content. We may
          provide you with the opportunity to create, submit, post, display,
          transmit, perform, publish, distribute, or broadcast content and
          materials to us or on the Site, including but not limited to text,
          writings, video, audio, photographs, graphics, comments, suggestions,
          or personal information or other material (collectively,
          "Contributions"). Contributions may be viewable by other users of the
          Site and through third-party websites. As such, any Contributions you
          transmit may be treated in accordance with the Site Privacy Policy.
          When you create or make available any Contributions, you thereby
          represent and warrant that:
        </p>
        <ul className='unorderedList'>
          <li>
            The creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            You are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the Site, and other users of the Site to use your Contributions
            in any manner contemplated by the Site and these Terms of Use.
          </li>
          <li>
            You have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the Site and these Terms of Use.
          </li>
          <li>Your Contributions are not false, inaccurate, or misleading.</li>
          <li>
            Your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.
          </li>
          <li>
            Your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>
          <li>
            Your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>
          <li>
            Your Contributions are not used to harass or threaten (in the legal
            sense of those terms) any other person and to promote violence
            against a specific person or class of people.
          </li>
          <li>
            Your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            Your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            Your Contributions do not violate any applicable law concerning
            child pornography, or otherwise intended to protect the health or
            well-being of minors.
          </li>
          <li>
            Your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            Your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms of Use, or any
            applicable law or regulation.
          </li>
          <li>
            In the event that your Contributions violate these, or other
            conditions within the scope of the Site's Term and Conditions and
            other policies, leads to damages or losses of the Site and the
            Site's business associates, you will be held responsible for the
            restitution of the Site and/or Site's businesses associates losses,
            damages, or any other detrimental result financial or otherwise.
          </li>
        </ul>
        <p>
          <br />
          <br />
          Any use of the Site in violation of the foregoing violates these Terms
          of Use and may result in, among other things, termination or
          suspension of your rights to use the Site.
        </p>
        <div className="underline"></div>
        <h2>6. Contribution License</h2>
        <p>
          You and the Site agree that we may access, store, process, and use any
          information and personal data that you provide following the terms of
          the Privacy Policy and your choices (including settings).
          <br />
          <br />
          By submitting suggestions or other feedback regarding the Site, you
          agree that we can use and share such feedback for any purpose without
          compensation to you.
          <br />
          <br />
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area on the Site. You are
          solely responsible for your Contributions to the Site and you
          expressly agree to exonerate us from any and all responsibility and to
          refrain from any legal action against us regarding your Contributions.
        </p>
        <div className="underline"></div>
        <h2>7. Guidelines for Reviews</h2>
        <p>
          We may provide you areas on the Site to leave reviews or ratings. When
          posting a review, you must comply with the following criteria: (1) you
          should have firsthand experience with the person/entity being
          reviewed; (2) your reviews should not contain offensive profanity, or
          abusive, racist, offensive, or hate language; (3) your reviews should
          not contain discriminatory references based on religion, race, gender,
          national origin, age, marital status, sexual orientation, or
          disability; (4) your reviews should not contain references to illegal
          activity; (5) you should not be affiliated with competitors if posting
          negative reviews; (6) you should not make any conclusions as to the
          legality of conduct; (7) you may not post any false or misleading
          statements; and (8) you may not organize a campaign encouraging others
          to post reviews, whether positive or negative.
          <br />
          <br />
          We may accept, reject, or remove reviews in our sole discretion. We
          have absolutely no obligation to screen reviews or to delete reviews,
          even if anyone considers reviews objectionable or inaccurate. Reviews
          are not endorsed by us, and do not necessarily represent our opinions
          or the views of any of our affiliates or partners. We do not assume
          liability for any review or for any claims, liabilities, or losses
          resulting from any review. By posting a review, you hereby grant to us
          a perpetual, non-exclusive, worldwide, royalty-free, fully-paid,
          assignable, and sublicensable right and license to reproduce, modify,
          translate, transmit by any means, display, perform, and/or distribute
          all content relating to reviews.
        </p>
        <div className="underline"></div>
        <h2>8. Submissions</h2>
        <p>
          You acknowledge and agree that any questions, comments, suggestions,
          ideas, feedback, or other information regarding the Site
          ("Submissions") provided by you to us are non-confidential and shall
          become our sole property. We shall own exclusive rights, including all
          intellectual property rights, and shall be entitled to the
          unrestricted use and dissemination of these Submissions for any lawful
          purpose, commercial or otherwise, without acknowledgment or
          compensation to you. You hereby waive all moral rights to any such
          Submissions, and you hereby warrant that any such Submissions are
          original with you or that you have the right to submit such
          Submissions. You agree there shall be no recourse against us for any
          alleged or actual infringement or misappropriation of any proprietary
          right in your Submissions.
        </p>
        <div className="underline"></div>
        <h2>9. Third-Party Website and Content</h2>
        <p>
          The Site may contain (or you may be sent via the Site) links to other
          websites ("Third-Party Websites") as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information,
          applications, software, and other content or items belonging to or
          originating from third parties ("Third-Party Content"). Such
          Third-Party Websites and Third-Party Content are not investigated,
          monitored, or checked for accuracy, appropriateness, or completeness
          by us, and we are not responsible for any Third-Party Websites
          accessed through the Site or any Third-Party Content posted on,
          available through, or installed from the Site, including the content,
          accuracy, offensiveness, opinions, reliability, privacy practices, or
          other policies of or contained in the Third-Party Websites or the
          Third-Party Content. Inclusion of, linking to, or permitting the use
          or installation of any Third-Party Websites or any Third-Party Content
          does not imply approval or endorsement thereof by us. If you decide to
          leave the Site and access the Third-Party Websites or to use or
          install any Third-Party Content, you do so at your own risk, and you
          should be aware these Terms of Use no longer govern. You should review
          the applicable terms and policies, including privacy and data
          gathering practices, of any website to which you navigate from the
          Site or relating to any applications you use or install from the Site.
          Any purchases you make through Third-Party Websites will be through
          other websites and from other companies, and we take no responsibility
          whatsoever in relation to such purchases which are exclusively between
          you and the applicable third party. You agree and acknowledge that we
          do not endorse the products or services offered on Third-Party
          Websites and you shall hold us harmless from any harm caused by your
          purchase of such products or services. Additionally, you shall hold us
          harmless from any losses sustained by you or harm caused to you
          relating to or resulting in any way from any Third-Party Content or
          any contact with Third-Party Websites.
        </p>
        <div className="underline"></div>
        <h2>10. Site Management</h2>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Site
          for violations of these Terms of Use; (2) take appropriate legal
          action against anyone who, in our sole discretion, violates the law or
          these Terms of Use, including without limitation, reporting such user
          to law enforcement authorities; (3) in our sole discretion and without
          limitation, refuse, restrict access to, limit the availability of, or
          disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Site or
          otherwise disable all files and content that are excessive in size or
          are in any way burdensome to our systems; and (5) otherwise manage the
          Site in a manner designed to protect our rights and property and to
          facilitate the proper functioning of the Site.
        </p>
        <div className="underline"></div>
        <h2>11. Privacy Policy</h2>
        <p>
          We care about data privacy and security. Please review our Privacy
          Policy:{" "}
          <a href="/privacy_policy">
            http://www.evidencednutrition.com/privacy_policy
          </a>
          . By using the Site, you agree to be bound by our Privacy Policy,
          which is incorporated into these Terms of Use. Please be advised the
          Site is hosted in the United States. . If you access the Site from any
          other region of the world with laws or other requirements governing
          personal data collection, use, or disclosure that differ from
          applicable laws in the United States, the your continued use of the
          Site, you are transferring your data to the United States, and you
          agree to have your data transferred to and processed in the United
          States.
        </p>
        <div className="underline"></div>
        <h2>12. Term and Termination</h2>
        <p>
          These Terms of Use shall remain in full force and effect while you use
          the Site. WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE,
          WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
          LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
          CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
          INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR INFORMATION THAT
          YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
          <br />
          <br />
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
        </p>
        <div className="underline"></div>
        <h2>13. Modifications and Interrupstions</h2>
        <p>
          We reserve the right to change, modify, or remove the contents of the
          Site at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Site. We also reserve the right to modify or discontinue all or
          part of the Site without notice at any time. We will not be liable to
          you or any third party for any modification, price change, suspension,
          or discontinuance of the Site.
          <br />
          <br />
          We cannot guarantee the Site will be available at all times. We may
          experience hardware, software, or other problems or need to perform
          maintenance related to the Site, resulting in interruptions, delays,
          or errors. We reserve the right to change, revise, update, suspend,
          discontinue, or otherwise modify the Site at any time or for any
          reason without notice to you. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site during any downtime or
          discontinuance of the Site. Nothing in these Terms of Use will be
          construed to obligate us to maintain and support the Site or to supply
          any corrections, updates, or releases in connection therewith.
        </p>
        <div className="underline"></div>
        <h2>14. Governing Law</h2>
        <p>
          These Terms of Use and your use of the Site are governed by and
          construed in accordance with the laws of the State of Ohio applicable
          to agreements made and to be entirely performed within the State of
          Ohio, without regard to its conflict of law principles.
        </p>
        <div className="underline"></div>
        <h2>15. Dispute Resolution</h2>
        <h3>Informal Negotiations</h3>
        <p>
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Terms of Use (each "Dispute"
          and collectively, the “Disputes”) brought by either you or us
          (individually, a “Party” and collectively, the “Parties”), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least thirty (30) days
          before initiating arbitration. Such informal negotiations commence
          upon written notice from on Party to the other Party.
        </p>
        <h3>Binding Arbitration</h3>
        <p>
          If the Parties are unable to resolve a Dispute through informal
          negotiations, the Dispute (except those Disputes expressly excluded
          below) will be finally and exclusively resolved through binding
          arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD
          HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration
          shall be commenced and conducted under the Commercial Arbitration
          Rules of the American Arbitration Association ("AAA") and, where
          appropriate, the AAA’s Supplementary Procedures for Consumer Related
          Disputes ("AAA Consumer Rules"), both of which are available at the
          AAA website: www.adr.org. Your arbitration fees and your share of
          arbitrator compensation shall be governed by the AAA Consumer Rules
          and, where appropriate, limited by the AAA Consumer Rules. The
          arbitration may be conducted in person, through the submission of
          documents, by phone, or online. The arbitrator will make a decision in
          writing, but need not provide a statement of reasons unless requested
          by either Party. The arbitrator must follow applicable law, and any
          award may be challenged if the arbitrator fails to do so. Except where
          otherwise required by the applicable AAA rules or applicable law, the
          arbitration will take place in Cuyahoga, Ohio. . Except as otherwise
          provided herein, the Parties may litigate in court to compel
          arbitration, stay proceedings pending arbitration, or to confirm,
          modify, vacate, or enter judgment on the award entered by the
          arbitrator.
          <br />
          <br />
          If for any reason, a Dispute proceeds in court rather than
          arbitration, the Dispute shall be commenced or prosecuted in the state
          and federal courts located in Cuyahoga, OH, and the Parties hereby
          consent to, and waive all defenses of lack of personal jurisdiction,
          and forum non conveniens with respect to venue and jurisdiction in
          such state and federal courts. Application of the United Nations
          Convention on Contracts for the International Sale of Goods and the
          Uniform Computer Information Transaction Act (UCITA) is excluded from
          these Terms of Use.
          <br />
          <br />
          In no event shall any Dispute brought by either Party related in any
          way to the Site be commenced more than one (1) years after the cause
          of action arose. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable, and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
        <h3>Restrictions</h3>
        <p>
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilize class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
        </p>
        <h3>Exceptions to Informal Negotiations and Arbirtration</h3>
        <p>
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations and binding
          arbitration: (a) any Disputes seeking to enforce or protect, or
          concerning the validity of, any of the intellectual property rights of
          a Party; (b) any Dispute related to, or arising from, allegations of
          theft, piracy, invasion of privacy, or unauthorized use; and (c) any
          claim for injunctive relief. If this provision is found to be illegal
          or unenforceable, then neither Party will elect to arbitrate any
          Dispute falling within that portion of this provision found to be
          illegal or unenforceable and such Dispute shall be decided by a court
          of competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
        </p>
        <div className="underline"></div>
        <h2>16 Corrections</h2>
        <p>
          There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Site at any time, without prior notice.
        </p>
        <div className="underline"></div>
        <h2>17. Disclaimer</h2>
        <p>
          THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
          THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
          TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
          EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY
          WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
          RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF
          CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY
          NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SITE,
          (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY
          AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
          THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
          THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY
          BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR (6)
          ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS
          OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
          POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
          NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
          PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
          FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
          PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
          BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS
          WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
          ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
          WHERE APPROPRIATE.
        </p>
        <div className="underline"></div>
        <h2>18. Limitations of Liability</h2>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES.
        </p>
        <div className="underline"></div>
        <h2>19. Idemnification</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) use of the site;
          (2) breach of these Terms of Use; (3) any breach o your
          representations and warranties set forth in these Terms of Use; (4)
          your violation of the rights of a third party, including but not
          limited to intellectual property rights; or (5) any overt harmful act
          toward any other user of the Site with whom you connected via the
          Site. Notwithstanding the foregoing, we reserve the right, at your
          expense, to assume the exclusive defense and control of any matter for
          which you are required to indemnify us, and you agree to cooperate, at
          your expense, with our defense of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
        </p>
        <div className="underline"></div>
        <h2>20. User Data</h2>
        <p>
          We will maintain certain data that you transmit to the Site for the
          purpose of managing the performance of the Site, as well as data
          relating to your use of the Site. Although we perform regular routine
          backups of data, you are solely responsible for all data that you
          transmit or that relates to any activity you have undertaken using the
          Site. You agree that we shall have no liability to you for any loss or
          corruption of any such data, and you hereby waive any right of action
          against us arising from any such loss or corruption of such data.
        </p>
        <div className="underline"></div>
        <h2>21. Electronic Communications, Transactions, and Signatures</h2>
        <p>
          Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
        </p>
        <div className="underline"></div>
        <h2>22. California Users and Residents</h2>
        <p>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
        </p>
        <div className="underline"></div>
        <h2>23. Refund and Chargeback Policy </h2>
        <h3>1. Refunds on Fraudulent Charges</h3>
        <p>
          All sales are final and are ineligible for refund of any percentage,
          with the exception of legitimate fraud. In order to prove legitimate
          fraud the customer must contact us in written form, either in the form
          of email, our site's contact forms, or written letters, alerting us
          that you believe you have been the subject of fraud before alerting
          your bank and initiating a chargeback, as outlined by The Fair Credit
          and Billing Act. While we take as many methods as possible to prevent
          fraudulent charges, payment followed by delivery of goods or services
          followed by chargebacks with no warning, is the standard sign of
          Friendly Fraud. If a chargeback is initiated without warning or reason
          from the cardholder after goods or services have been provided,
          Friendly Fraud will be determined as the cause, see subsection 3 for
          Chargeback Policy. In order to qualify for a fraudulent refund, the
          customer or a representative from their issuing bank, must provide the
          following:
        </p>
        <ul className="unorderedList">
          <li>Your bank or card issuer's contact information.</li>
          <li>A statement from the bank with an overview of the charge.</li>
          <li>
            Any and all other statements, if any, that show past charges from
            Cake Nutrition, LLC.
          </li>
          <li>The customer's Billing Address, verified by the issuing bank.</li>
        </ul>
        <p>
          Once we have received these items, we will verify the information from
          the charge in question. Should the charge be fraudulent, a one (1)
          time full refund will be issued to the customer. If the charge in
          question is not verified by the information provided by the customer,
          then no refund will be issued. See subsection 3 for more details.
        </p>
        <h3>2. Partial Refunds and Exchanges</h3>
        <p>
          No partial refunds or exchanges will be made. All sales are final once
          the service has been initiated with the client.
        </p>
        <h3>3. Chargeback Policy</h3>
        <p>
          In the event that either: (1) a service or product had been purchased
          under fraudulent circumstances (such as a stolen debit/credit card or
          payment information); (2) the customer has not fulfilled the
          obligations of subsection 1 to prove fraudulent activity to us; (3)
          the customer has acted in bad faith (here on called "disputer") and
          committed Friendly Fraud, the following procedure will follow.
        </p>
        <ol className="orderedList">
          <li>
            The chargeback will be immediately disputed and any and all charges
            associated with the disputer, even if not related to the payment
            used for the disputed charge, will be flagged for Friendly Fraud.
          </li>
          <li>
            This will be considered a Dispute by the definition of these Terms,
            and will follow the Terms outlined in sections 14 and 15 regarding
            Disputes, Arbitration and Litigation.
          </li>
          <li>
            Any and all documentation and data relating to the charge and the
            disputer's account, excluding any sensitive medical or HIPAA
            protected information, will be collected and submitted to the
            disputer's bank or card issuer.
          </li>
          <li>
            We will attempt to contact the disputer to have the chargeback
            reversed, citing documentation collected and sent to their financial
            institution. Should the chargeback be cancelled within two (2)
            business days, the matter will be considered resolved.
          </li>
          <li>
            In the event the chargeback is not cancelled, then the process for
            Dispute Resolution will take place, as laid out in sections 14 and
            15 of these Terms.
          </li>
          <li>
            Should the Dispute reach litigation, civil and criminal charges may
            be pursued against the disputer by us under the jurisdiction set
            forth in sections 14 and 15.
          </li>
          In the event that the disputed charge is legitimate, and in the event
          the disputor requires evidence of the fraudulent charge, we will
          provide all relevent information, excluding sensitive medical or HIPAA
          protected information, to the disputor and/or their financial
          institution and relevent law enforcement and or representation, under
          the condition that in the event the fraudulent party is discovered, we
          retain the right to seek restitution for damages and losses incurred
          from responsible parties.
        </ol>
        <div className="underline"></div>
        <h2>24. Miscellaneious</h2>
        <p>
          These Terms of Use and any policies or operating rules posted by us on
          the Site or in respect to the Site constitute the entire agreement and
          understanding between you and us. Our failure to exercise or enforce
          any right or provision of these Terms of Use shall not operate as a
          waiver of such right or provision. These Terms of Use operate to the
          fullest extent permissible by law. We may assign any or all of our
          rights and obligations to others at any time. We shall not be
          responsible or liable for any loss, damage, delay, or failure to act
          caused by any cause beyond our reasonable control. If any provision or
          part of a provision of these Terms of Use is determined to be
          unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Terms of Use and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms of
          Use or use of the Site. You agree that these Terms of Use will not be
          construed against us by virtue of having drafted them. You hereby
          waive any and all defenses you may have based on the electronic form
          of these Terms of Use and the lack of signing by the parties hereto to
          execute these Terms of Use.
          <br />
          <br />
          All activity, data transferred, and services or contracts entered into
          are solely between the user and Cake Nutrition, LLC. Ley Line
          Development, employees and representatives are independent contractors
          providing technical support for Cake Nutrition, LLC, and cannot be
          held liable for any breach in data or personal information, services
          not rendered, disputes between users and service providers, or any
          other damages or losses that may be incurred by the user, provider, or
          Cake Nutrition, LLC.
        </p>
        <div className="underline"></div>
      </div>
    </div>
  );
}
