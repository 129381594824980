import React, { useState } from 'react';
import axios from 'axios';

export default function PassReset() {
  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log('password reset initiated...');
    axios
      .put(`${process.env.REACT_APP_BASE_URL}/auth/reset-password`, {
        email: user.username,
        password: user.password,
      })
      .then((res) => {
        if (res.status !== 200) {
          window.location = '/unauthorized';
        }
        window.localStorage.setItem('auth', res.data.token);
        window.alert(
          'Password Reset Successful. You may return to the login page'
        );
        setTimeout(() => {
          window.location = '/login';
        }, 3000);
      })
      .catch(function (error) {
        if (error.response) {
          window.alert(error.response.status);
        }
      });
  };

  return (
    <div
      style={{
        width: '60%',
      }}
    >
      <h1>Reset Password</h1>
      <div
        className="loginForm"
        style={{
          width: '100%',
        }}
      >
        <form>
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            placeholder="Enter Username"
            name="username"
            value={user.username}
            style={{
              width: '50%',
            }}
            onChange={handleChange}
          />
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter Password"
            name="password"
            value={user.password}
            style={{
              width: '50%',
            }}
            onChange={handleChange}
          />
          <button
            onClick={handleClick}
            style={{
              margin: 'auto',
              marginTop: '2%',
              marginBottom: '2%',
            }}
          >
            Submit
          </button>
          <a href="/login">Login</a>
        </form>
      </div>
    </div>
  );
}
