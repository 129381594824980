import React from "react";
import ReleaseLogo from "../utils/ReleaseLogo";

export default function ReleaseForm() {

  return (
    <div className="ReleaseForm">
      <div className="wrapper">
        <ReleaseLogo />
        <div className="title">
          WAIVER AND CONSENT
          <div className="subtitle">
            Release of Liability for Receipt of Nutrition releaseInformation and Waiver
            of Claims Arising From Receipt of Nutrition releaseInformation
          </div>
        </div>
        {/* SECTION 1 */}
        <div className="section">
          <div className="content">
            <div className="title">Disclaimer</div>
            <div className="details">
              The nutrition releaseInformation provided by (PROP GOES HERE) is designed
              for and solely intended to be suggestions, which may voluntarily
              be implemented into the diet of the person whose signature appears
              below (“the client”). Use of any nutrition releaseInformation provided is
              voluntary and each user is solely responsible for their voluntary
              choice to implement the dietary suggestions. It is the sole
              responsibility of the client to provide complete and accurate
              releaseInformation. Any misreleaseInformation or omitted releaseInformation may affect
              the nutritional assessment or advice. Any misrepresented
              releaseInformation is solely the client’s responsibility and (PROP GOES
              HERE) will not be liable
              <br />
              <br />
              The Registered Dietitian provides nutrition consulting and
              recommendations only and is not licensed to diagnose a medical
              condition or illness. The client must consult a physician for any
              medical advice.
            </div>
            <div className="initials">
              <h3>Initials: </h3>
              <p className="initial">Initial</p>
            </div>
          </div>
        </div>
        {/* END SECTION 1 */}

        {/* SECTION 2 */}
        <div className="section">
          <div className="content">
            <div className="title">Acknowledgement of Purchase</div>
            <div className="details">
              I,(PROP GOES HERE) , through the purchase of Registered Dietitian
              sessions, have agreed to participate voluntarily in a nutrition
              program under the guidance of (PROP GOES HERE), Family Wellness,
              its authorized agents, employees, and contractors (“Registered
              Dietitian”).{" "}
            </div>
            <div className="initials">
              <h3>Initials: </h3>
              <p className="initial">Initial</p>
            </div>{" "}
          </div>
        </div>
        {/* END SECTION 2 */}

        {/* SECTION 3 */}
        <div className="section">
          <div className="content">
            <div className="title">Waiver and Release of Liability</div>
            <div className="details">
              I have volunteered to participate in a wellness program under the
              direction of ( PROP GOES HERE), which will include, but may not be
              limited to nutritional planning. In consideration of my Registered
              Dietitian’s agreement to assist me, I do here and forever release
              and discharge and hereby hold harmless the Registered Dietitian
              and his/ her respective agents, heirs, assigns, contractors, and
              employees from any and all claims, demands, damages, rights of
              action or causes of action, present or future, arising out of or
              connected with my participation in any nutrition program including
              any injuries resulting there from.{" "}
            </div>
            <div className="initials">
              <h3>Initials: </h3>
              <p className="initial">Initial</p>
            </div>{" "}
          </div>
        </div>
        {/* END SECTION 3 */}

        {/* SECTION 4 */}
        <div className="section">
          <div className="content">
            <div className="title agreement">
              Client/Registered Dietitian Agreement
            </div>
            <div className="subtitle">
              By signing this agreement, I am agreeing to the following terms of
              Family Wellness:
            </div>
            <div className="details">
              <ul>
                <li>
                  Full Payment is required prior to each session used. Clients
                  will be required to have a card on file to enable Family
                  Wellness to charge your account if session is not paid
                  beforehand.
                </li>
                <li>
                  Discounted prices are applicable only if I pay for multiple
                  sessions in full prior to my first appointment using those
                  multiple sessions. I will be charged for a cancelled
                  appointment unless I notify Family Wellness of cancellation at
                  least 24 hours prior to the scheduled time. If I am late for
                  my appointment, I agree that the lost time will be forfeited,
                  but I will be charged for that session.
                </li>
                <li>
                  I understand that Family Wellness will try to accommodate
                  preferences for certain appointment times and specific
                  Registered Dietitian requests, but cannot guarantee
                  availability due to other appointments, scheduling conflicts,
                  and other factors
                </li>
                <li>
                  I understand that the staff and/or instructor will not be held
                  responsible for any injuries, illnesses, or expenses from my
                  participation, especially if I have neglected to disclose
                  known medical condition or similar releaseInformation about myself
                  that might affect my ability to participate.
                </li>
                <li>
                  In signing below, I agree to the above conditions as well as
                  other policies of the facility. I also acknowledge that I have
                  received and understand the Consent and Release form from
                  Family Wellness.
                </li>
                <li>
                  If client is a “no show,” after 15 minutes without 24 hour
                  prior verbal or written notice the client will be charged for
                  that session
                </li>
                <li>
                  Registered Dietitian and clients are expected to confirm
                  meeting times so there is no misunderstanding.{" "}
                </li>
                <li>
                  Registered Dietitian sessions expire one year from date of
                  purchase.
                </li>
              </ul>
            </div>
            <div className="signatures">
              <div className="participant">
                <div className="name">
                  <div className="releaseInfo">
                    <h3 className="item">Client's Name: </h3>
                    <p className="print">(PROP GOES HERE)</p>
                  </div>
                  <div className="date">
                    <h3 className="item">Date: </h3>
                    <p className="print timestamp">(DATE GOES HERE)</p>
                  </div>
                </div>
                <div className="signature">
                  <div className="releaseInfo">
                    <h3 className="item">Client's Signature: </h3>
                    <p className="sign cursive">Matthew Bergeron</p>
                  </div>
                  <div className="date">
                    <h3 className="item">Date: </h3>
                    <p className="print timestamp">(DATE GOES HERE)</p>
                  </div>
                </div>
              </div>
              <div className="provider">
                <div className="name">
                  <div className="releaseInfo">
                    <h3 className="item">Provider's Name: </h3>
                    <p className="print">Evidenced Nutrition</p>
                  </div>
                  <div className="date">
                    <h3 className="item">Date: </h3>
                    <p className="print timestamp">(DATE GOES HERE)</p>
                  </div>
                </div>
                <div className="signature">
                  <div className="releaseInfo">
                    <h3 className="item">Provider's Signature: </h3>
                    <p className="sign cursive2">Jaob Mey</p>
                  </div>
                  <div className="date">
                    <h3 className="item">Date: </h3>
                    <p className="print timestamp">(DATE GOES HERE)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END SECTION 5 */}
      </div>
    </div>
  );
}
