import React from "react";

const YouTubeIcon = () => {
  return (
    <a href="https://www.youtube.com/channel/UC_NBM0TbbayeHcLe5fJ2exA">
    <svg className="footer-icon"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7676 7.16261C15.6556 6.61211 20.3446 6.61211 24.2326 7.16261C27.7651 7.66361 30.5026 10.1416 31.0246 12.8116C31.6591 16.0441 31.6591 19.9561 31.0246 23.1886C30.5011 25.8586 27.7651 28.3366 24.2326 28.8376C20.3446 29.3881 15.6541 29.3881 11.7676 28.8376C8.23511 28.3366 5.49911 25.8586 4.97561 23.1886C4.34111 19.9561 4.34111 16.0441 4.97561 12.8116C5.49911 10.1416 8.23511 7.66361 11.7676 7.16261ZM24.6526 4.19261C20.4871 3.60311 15.5131 3.60311 11.3476 4.19261C6.82511 4.83311 2.85011 8.05961 2.03261 12.2356C1.32311 15.8491 1.32311 20.1511 2.03261 23.7646C2.85011 27.9406 6.82511 31.1671 11.3476 31.8076C15.5131 32.3971 20.4871 32.3971 24.6526 31.8076C29.1751 31.1671 33.1501 27.9406 33.9676 23.7646C34.6771 20.1511 34.6771 15.8491 33.9676 12.2356C33.1501 8.05961 29.1751 4.83311 24.6526 4.19261ZM15.8326 10.7521C15.6067 10.6014 15.3441 10.5148 15.0729 10.5017C14.8016 10.4885 14.5319 10.5492 14.2925 10.6773C14.053 10.8054 13.8528 10.9961 13.7133 11.2291C13.5738 11.4621 13.5001 11.7286 13.5001 12.0001V24.0001C13.5001 24.2717 13.5738 24.5382 13.7133 24.7711C13.8528 25.0041 14.053 25.1948 14.2925 25.3229C14.5319 25.451 14.8016 25.5118 15.0729 25.4986C15.3441 25.4854 15.6067 25.3988 15.8326 25.2481L24.8326 19.2481C25.0381 19.1111 25.2065 18.9256 25.323 18.7078C25.4395 18.4901 25.5005 18.247 25.5005 18.0001C25.5005 17.7532 25.4395 17.5101 25.323 17.2924C25.2065 17.0747 25.0381 16.8891 24.8326 16.7521L15.8326 10.7521ZM21.2956 18.0001L16.5001 21.1981V14.8036L21.2956 18.0001Z"
        fill="#F1FAEE"
      />
    </svg>
    </a>
  );
};

export default YouTubeIcon;
