import React, { useState } from "react";
import headshot from "../images/headshot.jpg";
import meeting from "../images/meeting.jpg";
import { sendEmail } from "../utils/emails/newSubscriber";

import EmbeddedVideo from "./EmbeddedVideo";

export default function LandingPage() {
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="landing">
      <div className="wrapper">
        <div className="landing-container">
          <div className="hero">
            <div className="hero-content">
              <h2>Why a Registered Dietitian?</h2>
              <p className='large' style={{
                fontSize: '2.5rem'
              }}>Tired of cookie-cutter nutrition programs not working?</p>
              <p className='med' style={{
                fontSize: '1.25rem'
              }}>Use Precision Nutrition to get TARGETED results in the FASTEST and EASIEST way possible!</p>
              <p className='small' style={{
                fontSize: '1.5rem',
                lineHeight: '2rem',
                marginBottom: '1%'
              }}>
                Precision Nutrition uses your biometrics, demographics and lifestyle habits to develop a unique
                approach for you to see results with scientific efficiency! </p>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  window.location = "/why_choose_a_RD";
                }}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="email-form">
            <h2 className="title">
              Expertise and Research Work! Learn Why Today!
            </h2>
            <form
              className="input-form"
              onSubmit={(e) => {
                e.preventDefault();
                sendEmail(visitor.fName, visitor.lName, visitor.email);
              }}
            >
              <input
                id="firstName"
                type="text"
                name="fName"
                value={visitor.fName}
                placeholder="FIRST NAME"
                required
                onChange={handleChange}
              />
              <hr className="vert-divider-blue" />
              <input
                id="firstName"
                type="text"
                name="lName"
                value={visitor.lName}
                placeholder="LAST NAME"
                required
                onChange={handleChange}
              />
              <hr className="vert-divider-blue" />
              <input
                id="email"
                className="cta_email"
                type="email"
                name="email"
                value={visitor.email}
                placeholder="EMAIL"
                required
                onChange={handleChange}
              />
              <button className="button sub" type="submit">
                Send
              </button>
            </form>
          </div>
          <div className="landing-about">
            <div className="landing-about-wrapper">
              <div className="headshot">
                <img src={headshot} alt="headshot" />
              </div>
              <div className="landing-about-content">
                <h3>Meet Dr. Jacob Mey, PhD, RD</h3>
                <p>
                  Dr Mey is not only a practiced Registered and Licensed
                  Dietitian, he is also one of the leading experts in human
                  nutrition and metabolism. Being an active researcher gives him
                  cutting edge knowledge on the impact of nutrition on the body
                  that few others have.
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location = "/about";
                  }}
                >
                  <span>Read More</span>
                </button>
              </div>
            </div>
          </div>
          <div className="intro-video">
            <EmbeddedVideo embedId="cvOKNXZ7Oeo" />
          </div>
          <div className="landing-call-to-action">
            <div className="landing-call-to-action-wrapper">
              <div className="meeting">
                <img src={meeting} alt="meeting" style={{paddingLeft: '1rem'}} />
              </div>
              <div className="landing-call-to-action-content">
                <h3 style={{margin: 'auto'}}>Wellness for Individuals</h3>
                <h3 style={{margin: 'auto'}}>Wellness for Everyone</h3>
                <p style={{ width: '70%', margin: 'auto', textAlign: 'center'}}>
                  Whether you are looking for yourself, or you are looking for
                  Corporate Wellness Plan for your company, Dr. Mey can help!
                </p>
                <ul className="service-list" >
                  <li>Metabolic Consultation</li>
                  <li>Nutrition Therapy</li>
                  <li>Lab Grade Measurement</li>
                  <li>Lifestyle Counseling</li>
                  <li>Targeting Intervention</li>
                  <li>Corporate Seminars</li>
                </ul>
                <button
                  className="form-button"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/request_consult";
                  }}
                  style={{
                    margin: 'auto'
                  }}
                >
                  <span>
                    Contact me today for your FREE Compatability Interview!
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
