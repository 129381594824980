import React, { useState, useEffect } from 'react';
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import { paymentSuccess, paymentFail } from '../utils/alerts/paymentAlert';

import emailjs from '@emailjs/browser';

const sendEmail = (
  receipt,
  service,
  customerInfoFName,
  customerInfoLName,
  customerInfoEmail
) => {
  emailjs
    .send(
      'default_service',
      `template_1wqj13z`,
      {
        to_name: 'Jake',
        customerInfoFName,
        customerInfoLName,
        customerInfoEmail,
        service,
        receipt,
      },
      'user_uscJE2OKi8jiqjr9ITiY4'
    )
    .then((res) => {
      console.log('email sent')
    })
    .catch((err) => {
      console.log('issue sending email')
    });
};


const CARD_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export default function PaymentForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [customerInfo, setCustomerInfo] = useState({
    customerInfoEmail: '',
    customerInfoFName: '',
    customerInfoLName: '',
  });
  const [, setCustomerId] = useState();
  const [price, setPrice] = useState();
  const [service, setService] = useState();
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    setCustomerId(localStorage.getItem('customerId'));
    setPrice(localStorage.getItem('price'));
    setCustomerInfo({
      customerInfoEmail: localStorage.getItem('visitor_email'),
      customerInfoFName: localStorage.getItem('visitor_fName'),
      customerInfoLName: localStorage.getItem('visitor_lName'),
    });

    setService(localStorage.getItem('service'));
  }, []);

  const [address, setAddress] = useState({
    addressCity: '',
    addressCountry: '',
    addressLine1: '',
    addressLine2: '',
    addressPostal_code: '',
    addressState: '',
  });

  const handleChanges = (e) => {
    setCustomerInfo({
      ...customerInfo,
      [e.target.name]: e.target.value,
    });

    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const handleValue = (e) => {
    e.preventDefault();

    address.addressState = e.target.value;
  };

  const handleSubmit = async () => {
    // await axios.get(`${process.env.REACT_APP_BASE_URL}/customers/all`).then((res) => {

    //   let list = res.data.list.data;
    //   let value = localStorage.getItem("customerId");

    //   for (var i = 0; i < list.length; i++) {
    //     if (list[i].id === value) {
    //       setCustomerId(list[i].id);
    //     }
    //   }
    // });

    // await axios
    //   .post(`${process.env.REACT_APP_BASE_URL}/customers/:id`, {
    //     id: customerId,
    //     address: address,
    //   })
    //   .then((res) => {
    //   })
    //   .catch((err) => console.log("error updating customer: ", err));

    const { error, paymentMethod } = await stripe.createPaymentMethod(
      {
        type: 'card',
        card: elements.getElement(CardElement),
      }
    );

    const PaymentElement = elements.getElement(CardElement);

    const token = await stripe.createToken(PaymentElement);

    let customer = {
      info: customerInfo,
      address: address,
    };

    if (!error) {
      setSubmit(true);
      try {
        await axios
          .post(`${process.env.REACT_APP_BASE_URL}/checkout`, {
            amount: price,
            customer: customer,
            token: token,
            paymentMethod,
          })
          .then((res) => {
            if (res.status !== 200) {
              paymentFail();
            } else {
              let receipt = res.data.intent
              sendEmail(
                receipt,
                service,
                customerInfo.customerInfoFName,
                customerInfo.customerInfoLName,
                customerInfo.customerInfoEmail
              );
              paymentSuccess();
            }
          })
          .catch((err) => {
            console.log('Error: ', err);
          });
      } catch (error) {
        console.log('Error: ', error);
      }
    } else {
      console.log('payment method not created');
    }
    // setTimeout(history.push("/"), 5000);
  };

  return (
    <div className="payment-form-container">
      <div className="input-form">
        <form
          htmlFor="customerInfoName"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="info">
            <h2>Customer Information</h2>
            <input
              required
              type="text"
              placeholder="First Name"
              className="input"
              name="customerInfoFName"
              onChange={handleChanges}
              value={customerInfo.customerInfoFName}
            />
            <input
              required
              type="text"
              placeholder="Last Name"
              className="input"
              name="customerInfoLName"
              onChange={handleChanges}
              value={customerInfo.customerInfoLName}
            />
            <input
              required
              placeholder="Email"
              type="text"
              name="customerInfoEmail"
              className="input"
              onChange={handleChanges}
              value={customerInfo.customerInfoEmail}
            />
          </div>
          <div className="info">
            <h2>Billing Information</h2>
            <input
              required
              placeholder="Street Address"
              type="text"
              name="addressLine1"
              className="input"
              onChange={handleChanges}
              value={address.addressLine1}
            />
            <input
              placeholder="Unit Number"
              type="text"
              name="addressLine2"
              className="input"
              onChange={handleChanges}
              value={address.addressLine2}
            />
            <input
              required
              placeholder="City"
              type="text"
              name="addressCity"
              className="input"
              onChange={handleChanges}
              value={address.addressCity}
            />
            <select
              required
              className="stateSelect input"
              name="state_select"
              onChange={handleValue}
            >
              <option>State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AS">American Samoa</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FM">
                Federated States Of Micronesia
              </option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="GU">Guam</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MH">Marshall Islands</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PW">Palau</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VI">Virgin Islands</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <input
              required
              placeholder="Zip Code"
              type="text"
              name="addressPostal_code"
              className="input"
              onChange={handleChanges}
              value={address.addressPostal_code}
            />
          </div>

          <fieldset className="FormGroup">
            <div className="FormRow">
              <CardElement options={CARD_OPTIONS} />
            </div>
          </fieldset>
          <button
            type="submit"
            className="stripe-button"
            disabled={submit}
          >
            Pay ${price / 100}
          </button>
        </form>
      </div>
    </div>
  );
}
