import React, { useState } from "react";
import WhiteLogo from "../utils/WhiteLogo";
import Facebook from "../utils/Facebook";
import Twitter from "../utils/Twitter";
import YouTube from "../utils/Youtube";
import { sendEmail } from "../utils/emails/newSubscriber";

export default function Footer() {
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="subscribe">
          <h3>Subscribe to the newsletter</h3>
          <p>
            Be the first to hear about new products, services, videos and more!
          </p>
          <form
            className="input-form"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(visitor.fName, visitor.lName, visitor.email);
            }}
          >
            <input
              required
              type="text"
              name="fName"
              value={visitor.fName}
              placeholder="FIRST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              type="text"
              name="lName"
              value={visitor.lName}
              placeholder="LAST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              className="cta_email"
              type="email"
              name="email"
              value={visitor.email}
              placeholder="EMAIL"
              onChange={handleChange}
            />
            <button className="button" type="submit">
              Send
            </button>
          </form>
        </div>
        <div className="branding">
          <div className="logo-container">
            <WhiteLogo />
          </div>
          <hr className="vert-divider" />
          <div className="social-icons">
            <p>Connect: </p>
            <Facebook />
            <Twitter />
            <YouTube />
          </div>
          <hr className="vert-divider" />
          <div className="email-link">
            <div className="email-link-wrapper">
              <p>Contact</p>
              <button
                onClick={() => {
                  window.location.href = "/contact";
                }}
                style={{
                  marginTop: '1rem'
                }}
              >
                Send Email
              </button>
            </div>
          </div>
        </div>
        <div className="disclosures">
          <div className="copyright">
            <p>Copyright Cake Nutrition 2021</p>
            <p>All Rights Reserved</p>
          </div>
          <div className="design">
            <p>
              Website by{" "}
              <a href="https://bergeronmatt.github.io/portfolio/#">
                Matt Bergeron
              </a>
            </p>
          </div>
          <div className="site-map">
            <a href="/privacy_policy">Privacy Policy</a>
            <a href="/terms_and_conditions">Terms and Conditions</a>
          </div>
        </div>
      </div>
    </div>
  );
}
