import React from 'react';

import HtmlEditor, {
  Toolbar,
  MediaResizing,
  Item,
} from 'devextreme-react/html-editor';
import 'devextreme/ui/html_editor/converters/markdown';
import axios from 'axios';

const sizeValues = [
  '8pt',
  '10pt',
  '12pt',
  '14pt',
  '18pt',
  '24pt',
  '36pt',
];
const fontValues = [
  'Arial',
  'Courier New',
  'Georgia',
  'Impact',
  'Lucida Console',
  'Tahoma',
  'Times New Roman',
  'Verdana',
];
const headerValues = [false, 1, 2, 3, 4, 5];

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      isMultiline: true,
      content: '',
    };

    this.multilineChanged = this.multilineChanged.bind(this);
  }

  render() {
    const title = this.props.title;

    const summary = this.props.summary;

    // console.log('summary: ', summary)

    // console.log('title: ', title)

    // function to turn the title into a metaTitle
    function generateMetaTitle(title) {
      let meta = title;

      return meta;
    }

    let metaTitle = generateMetaTitle(title);

    // function to create url slug
    function generateSlug(title) {
      return title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
    }

    let slug = generateSlug(title);

    const getContent = (e) => {
      // console.log('E check: ', e)
      this.setState({ content: e });
    };

    const handleSubmit = (e) => {
      e.preventDefault();

      let payload = {
        authorId: 0,
        title: title,
        summary: summary,
        metaTitle: metaTitle,
        slug: slug,
        content: this.state.content,
        published: 1,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        publishedAt: Date.now()
      };

      // const username = 'mjbergeron@leyline.dev';
      // const password = 'Newpass1234';
      // const token = window.localStorage.getItem('auth')

      axios.post(`${process.env.REACT_APP_BASE_URL}/blog/new-post`, { payload: payload })
      .then(res => {
          if (res.status !== 200) {
              window.location = '/unauthorized'
          } else if (res.status === 200) {
              window.localStorage.setItem('auth', res.data.token)
              window.alert('Post Published.')
              window.location = '/dashboard'
          }
      }).catch(function (error) {
          if (error.response) {
              window.alert(`${error.response.data.message}`)
          }
      }
      )
    };

    return (
      <div className="widget-container">
        <HtmlEditor
          height="725px"
          valueType="html"
          onValueChange={getContent}
          style={{
            background: 'white',
            border: '#c5c5c5 solid 1px',
          }}
        >
          <MediaResizing enabled={true} />
          <Toolbar multiline={this.state.isMultiline}>
            <Item name="undo" />
            <Item name="redo" />
            <Item name="separator" />
            <Item name="size" acceptedValues={sizeValues} />
            <Item name="font" acceptedValues={fontValues} />
            <Item name="separator" />
            <Item name="bold" />
            <Item name="italic" />
            <Item name="strike" />
            <Item name="underline" />
            <Item name="separator" />
            <Item name="alignLeft" />
            <Item name="alignCenter" />
            <Item name="alignRight" />
            <Item name="alignJustify" />
            <Item name="separator" />
            <Item name="orderedList" />
            <Item name="bulletList" />
            <Item name="separator" />
            <Item name="header" acceptedValues={headerValues} />
            <Item name="separator" />
            <Item name="color" />
            <Item name="background" />
            <Item name="separator" />
            <Item name="link" />
            <Item name="image" />
            <Item name="separator" />
            <Item name="clear" />
            <Item name="codeBlock" />
            <Item name="blockquote" />
            <Item name="separator" />
            <Item name="insertTable" />
            <Item name="deleteTable" />
            <Item name="insertRowAbove" />
            <Item name="insertRowBelow" />
            <Item name="deleteRow" />
            <Item name="insertColumnLeft" />
            <Item name="insertColumnRight" />
            <Item name="deleteColumn" />
          </Toolbar>
        </HtmlEditor>
        <button onClick={handleSubmit}>Submit</button>
      </div>
    );
  }

  multilineChanged(e) {
    this.setState({
      isMultiline: e.value,
    });
  }
}

export default App;
