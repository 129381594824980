import React, { useState } from 'react';
import axios from 'axios';
import { sendEmail } from '../utils/emails/questionnaireEmail';

export default function Questionnaire(props) {
  const [visitor, setVisitor] = useState({
    to_name: 'Jake',
    fName: '',
    lName: '',
    email: '',
    questionnaire_result: '',
    location: '',
  });

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    checkValue();
    await submitForm(visitor);
  };

  const submitForm = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth`)
      .then((res) => {
        localStorage.setItem('token', res.data.token);
        sendEmail(visitor);
      })
      .catch((err) => console.log('fail: ', err));

    window.location.reload();
  };

  const [q1, setQ1] = useState();
  const [q2, setQ2] = useState();
  const [q3, setQ3] = useState();
  const [q4, setQ4] = useState();
  const [q5, setQ5] = useState();

  const handleValue1 = (e) => {
    e.preventDefault();
    setQ1(e.target.value);
  };

  const handleValue2 = (e) => {
    e.preventDefault();
    setQ2(e.target.value);
  };

  const handleValue3 = (e) => {
    e.preventDefault();
    setQ3(e.target.value);
  };

  const handleValue4 = (e) => {
    e.preventDefault();
    setQ4(e.target.value);
  };

  const handleValue5 = (e) => {
    e.preventDefault();
    setQ5(e.target.value);
  };

  const checkValue = () => {
    if (
      Number(q5) === 1 ||
      Number(q4) === 1 ||
      Number(q3) === 4 ||
      Number(q2) === 4 ||
      Number(q1) === 3
    ) {
      visitor.questionnaire_result = 'request consult';
      visitor.location = '/request_consult';
    } else if (
      Number(q3) === 5 ||
      Number(q2) === 5 ||
      Number(q1) === 4 ||
      Number(q1) === 5
    ) {
      visitor.questionnaire_result = 'corporate package';
      visitor.location = '/corporate_wellness';
    } else if (Number(q3) === 3 || Number(q2) === 3) {
      visitor.questionnaire_result = 'advanced package';
      visitor.location = '/advanced_package';
    } else if (
      Number(q3) === 2 ||
      Number(q3) === 1 ||
      Number(q2) === 2 ||
      Number(q2) === 1 ||
      Number(q1) === 1
    ) {
      visitor.questionnaire_result = 'metabolic consultation';
      visitor.location = '/metabolic_consultation';
    }
  };

  return (
    <div className="questionnaire-container">
      <div className="close">
        <span onClick={props.toggleLearn}>x</span>
      </div>
      <form className="test-contact-form">
        <h1>Need help finding the right plan?</h1>
        <p>
          Just answer this short survey and you'll be redirected to
          the service best suited for you!
        </p>
        <div className="input_area">
          <select name="q1" id="q1" onChange={handleValue1}>
            <option>
              Are you interested in advice for yourself?
            </option>
            <option value="1">For myself</option>
            <option value="2">For a friend</option>
            <option value="3">For a patient or client</option>
            <option value="4">For a sports team</option>
            <option value="5">For my workplace</option>
          </select>
        </div>
        <div className="input_area">
          <select name="q2" id="q2" onChange={handleValue2}>
            <option>What are your goals with your diet?</option>
            <option value="1">None really, just browsing</option>
            <option value="2">
              Just improve food choices and general health
            </option>
            <option value="3">
              Looking to lose some weight, but need guidance
            </option>
            <option value="4">
              Want or need to improve health for specific reasons
            </option>
            <option value="5">
              Looking to help others with better knowledge
            </option>
          </select>
        </div>
        <div className="input_area">
          <select name="q3" id="q3" onChange={handleValue3}>
            <option>
              How would you describe your relation with food?
            </option>
            <option value={1}>
              I don't really think about it; just eat whenever
            </option>
            <option value={2}>
              I love food but rely on eating out for tasty food
            </option>
            <option value={3}>
              I struggle with meals because I'm so busy
            </option>
            <option value={4}>
              I'm very particular with what I need to eat
            </option>
            <option value={5}>
              I struggle with food options for others
            </option>
          </select>
        </div>
        <div className="input_area">
          <select name="q4" id="q4" onChange={handleValue4}>
            <option>
              Do you or the person(s) looking to help have specific
              dietary needs?
            </option>
            <option value={1}>Yes</option>
            <option value={2}>No</option>
            <option value={3}>Not Sure</option>
          </select>
        </div>
        <div className="input_area">
          <select name="q5" id="q5" onChange={handleValue5}>
            <option>
              Are you or the person(s) you're looking to help have
              medical needs?
            </option>
            <option value={1}>Yes</option>
            <option value={2}>No</option>
            <option value={3}>Not Sure</option>
          </select>
        </div>
        <div className="input_area">
          <input
            placeholder="First Name"
            type="text"
            id="fname"
            name="fName"
            onChange={handleChange}
            value={visitor.fName}
          />
        </div>
        <div className="input_area">
          <input
            placeholder="Last Name"
            type="text"
            id="lName"
            name="lName"
            onChange={handleChange}
            value={visitor.lName}
          />{' '}
        </div>
        <div className="input_area">
          <input
            placeholder="Email"
            type="email"
            id="email"
            name="email"
            onChange={handleChange}
            value={visitor.email}
          />
        </div>
        <input
          className="questionnaire-button"
          type="submit"
          value="Send"
          onClick={handleClick}
        />
      </form>
    </div>
  );
}
