import "./App.css";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { CookiesProvider } from "react-cookie";
import TagManager from "react-gtm-module";
import 'devextreme/dist/css/dx.light.css';


const tagManagerArgs = {
  gtmId: 'GTM-M8QL7RQ'
}

TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    pageview: 'pageview',
    event: 'event',
    eventProps: {
      category: 'category',
      action: 'action',
      label: 'label',
      value: 'value'
    }
  });

  return (
    <CookiesProvider>
      <div className="App">
        <div className="container">
          <Nav />
          <Footer />
        </div>
      </div>
    </CookiesProvider>
  );
}

export default App;
