import React, { useState, useEffect } from "react";
import Questionnaire from "./Questionnaire";
import axios from "axios";

export default function Popup(props) {
  const [isVisible, setIsVisible] = useState("hidden");

  function popupControl() {
    if (localStorage.getItem("token") != null) {
      setIsVisible("hidden");
    } else {
      setIsVisible("");
    }
  }

  useEffect(() => {
    setTimeout(popupControl, 10000);
  }, []);

  const toggleBox = (e) => {
    e.preventDefault();

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth`)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
      })
      .catch((err) => console.log("error at close button: ", err));
  };

  const toggleLearn = (e) => {
    e.preventDefault();

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/auth`)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        window.location.reload()
      })
      .catch((err) => console.log("error at close button: ", err));
  };

  return (
    <div className={`popup-container ${isVisible}`}>
      <div className={`popup`}>
        {/* <div className='close'>
          <span onClick={toggleLearn}>x</span>
        </div> */}
        <Questionnaire toggleBox={toggleBox} toggleLearn={toggleLearn}/>
        {/* <div className="disclaimer">
          <div className="col-1">
            <p>
              This website uses cookies to ensure you get the best experience on
              our website.
            </p>
          </div>
          <div className="col-2">
            <button className="learnMore" onClick={toggleLearn} >Learn More</button>
          </div>
          <div className="col-3">
            <button className="button" onClick={e => { e.preventDefault(); setIsVisible('hidden'); localStorage.setItem('token', 'null') }}>Reject Cookies</button>
          </div>
        </div> */}
      </div>
    </div>
  );
}
