
import React from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
// import Swal from 'sweetalert2'
import TextEditor from '../TextEditor'

export const BlogForm = () => {

    // const [title, setTitle] = useState(" ");
    // const [summary, setSummary] = useState(" ")
    // const [slug, setSlug] = useState(" ")
    // const [metaTitle, setMetaTitle] = useState(" ")

    // const handleTitle = (e) => {
    //     e.preventDefault()
        
    // }

    // const postSuccess = async ()=> {
    //     await Swal.fire({
    //         title: 'Post Published',
    //         text: 'Your post has been published successfully',
    //         timer: 5000,
    //         icon: 'success',
    //     }).then(() => {
    //         window.location.reload()
    //     });
    // }

  return (
    <div
        style={{
            width: '60%',
            margin: 'auto',
            marginBottom: "2%"
        }}
    >
        <h1>New Post</h1>
        <Formik
            initialValues={{
                title: '',
                metaTitle: '',
                slug: '',
                summary: '',
            }}
            validate={values => {
                const errors = {};
                if(!values.title) {
                    errors.title = "Post needs a title.";
                } else if(!values.summary) {
                    errors.summary = 'Post needs a summary.';
                } else if(!values.summary.length > 400) {
                    errors.summary_length = "Summary needs to be less than 400 characters."
                }
                return errors
            }}
            // onSubmit={(values, {setSubmitting}) => {
            //     let email = values.email;
            //     let password = values.password;
            // }}
        >
            {({values}) => (
                <div>
                    <Form>
                        <div
                            style={{
                                position: 'absolute',
                                left: '66%',
                                top: '42%'
                            }}
                        >
                            <p>{`Characters: ${values.summary.length}`}</p>
                        </div>
                        <Field
                            type="text"
                            name="title"
                            placeholder="Post Title"
                            value={values.title}
                            style={{
                                width: "100%",
                                height: '100px',
                                fontSize: '32px'
                            }}
                        />
                        <ErrorMessage name='title' component="div" />
                        <Field 
                            type='summary'
                            name='summary'
                            placeholder='Post Summary. Limit 400 characters'
                            value={values.summary}
                            style={{
                                width: '100%',
                                height: '100px',
                                fontSize: '18px'
                            }}
                        />
                        <ErrorMessage name='summary' component="div" />
                        <ErrorMessage name='summary_length' component="div" />
                    </Form>
                    <TextEditor title={values.title} summary={values.summary} />
                </div>
            )}
        </Formik>
        {/* <TextEditor /> */}
    </div>
  )
}
