import emailjs from '@emailjs/browser';
// init("user_uscJE2OKi8jiqjr9ITiY4");
import {
  subscriberSuccess,
  subscriberFail,
} from '../alerts/subscriberAlert';

export const sendEmail = (fName, lName, email) => {
  emailjs
    .send(
      'default_service',
      'template_k07l9bh',
      {
        fName,
        lName,
        email,
        to_name: 'Jake',
      },
      'user_uscJE2OKi8jiqjr9ITiY4'
    )
    .then((res) => {
      if (res.status !== 200) {
        subscriberFail();
      } else {
        subscriberSuccess();
      }
    })
    .catch((err) => {
      console.log('NEWS FAILED HERE', err);
    });
};
