import React from "react";
import PropTypes from "prop-types";

const EmbeddedVideo = ({ embedId }) => {

  return (
    <div className="embedded-video">
      <iframe
        width="80%"
        height="450"
        src={`https://www.youtube.com/embed/${embedId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

EmbeddedVideo.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default EmbeddedVideo;
