import React from 'react';

export default function BlogPageData(props) {
  const { data, loading } = props;

  // const cleanTitle = (data) => {
  //   let checkTitle = data.title.replace('amp;', '');
  //   return checkTitle;
  // };

  console.log('blog page data: ', data);
  console.log('loading: ', loading)

  return (
    <div
      style={{
        marginTop: '5%',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
        {data ? (
            <div>
                <h1
                    style={{
                        fontSize: '36px',
                        marginBottom: '2%'
                    }}
                >{data.title}</h1>
                <div 
                                dangerouslySetInnerHTML={{__html: data.content}} 
                                style={{
                                    marginTop: "5%",
                                    marginBottom: '5%',
                                    fontSize: '18px',
                                    width: '80%',
                                    margin: 'auto'
                                }}
                            />
            </div>
        ):(
            <div>
                <h1>Not Loading</h1>
            </div>
        )}
    </div>
  );
}
