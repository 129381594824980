import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
// import { sendEmail } from "../utils/sendEmail";
export default function Corporate() {
  const price = 300000;
  const customerUrl = `${process.env.REACT_APP_BASE_URL}/customers`;
  const [customer, setCustomer] = useState();
  const [customerId, setCustomerId] = useState();
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  const history = useHistory();

  useEffect(() => {
    localStorage.setItem("price", price);
    localStorage.setItem("customerId", customerId);
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);
    localStorage.setItem("service", "Corporate Seminar");

    setCustomer({
      address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      email: "",
      fName: "",
      lName: "",
    });

    setCustomerId("0");
  }, [customerId, visitor.email, visitor.fName, visitor.lName]);

  const handleClick = async (e) => {
    e.preventDefault();

    await axios
      .post(customerUrl, customer)
      .then((res) => {
        setCustomerId(res.data.id);
      })
      .catch((err) => {
        console.log("Error, could not make new customer", err);
      });

    localStorage.setItem("customerId", customerId);
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);

    history.push("/checkout");
  };
  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="service">
      <div className="wrapper">
        <h1>Corporate Wellness Seminar</h1>
        <div className="content">
          <div className="description">
            <p>
              The Corporate Wellness Seminars are opportunities for leaders
              within businesses or organizations to bring in expert opinions,
              research, and advice for any size group. Corporate Wellness
              doesn't have to be limited to just the office setting - it can be
              applied for the classroom and sports teams as well. Any group of
              individuals is a great reason to host a seminar and get the
              information and answers you need to get your team on track with a
              healthy, happier life in and out of the workplace.
              <br /> <br />
              The topic or topics of the presentation can be based on what you
              or your team is interested in or needs to learn more about.
              Seminars last 60 minutes, with a Q & A session. Individual
              consultations after the seminar by request in advance are also
              available, and highly recommended if you have members of your team
              who have special dietary requirements, or medical needs that need
              specific nutritional needs.
            </p>
            <br />
            <br />
            <p>
              Price: $3000.00 for virtual seminars; additional fees for lodging
              and travel if in person seminar.
            </p>
            <br />
            <p>
              Post Seminar 1-on-1 session: $100 per individual session post
              seminar
            </p>
          </div>
          <div className="form">
            <h3>Sign Up Now</h3>
            <form action="">
              <input
                name="fName"
                onChange={handleChange}
                value={visitor.fName}
                type="text"
                placeholder="First Name"
              />
              <input
                name="lName"
                onChange={handleChange}
                value={visitor.lName}
                type="text"
                placeholder="Last Name"
              />
              <input
                name="email"
                onChange={handleChange}
                value={visitor.email}
                type="email"
                placeholder="Email"
              />
              <button onClick={handleClick}>Sign Up</button>
            </form>
          </div>
        </div>
        {/* <div className="email-form">
          <h2>Questions? Send them here!</h2>
          <form
            className="input-form"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(visitor.fName, visitor.lName, visitor.email);
            }}
          >
            <input
              required
              type="text"
              name="fName"
              value={visitor.fName}
              placeholder="FIRST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              type="text"
              name="lName"
              value={visitor.lName}
              placeholder="LAST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              className="cta_email"
              type="email"
              name="email"
              value={visitor.email}
              placeholder="EMAIL"
              onChange={handleChange}
            />
            <button className="button" type="submit">
              Send
            </button>
          </form>
        </div> */}
      </div>
    </div>
  );
}
