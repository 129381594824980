import React from "react";

const FacebookIcon = () => {
  return (
    <a href="https://twitter.com/CakeNutrition">
      <svg
      className="footer-icon"
        width="36"
        height="36"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 6.00043C2 4.93956 2.42143 3.92215 3.17157 3.172C3.92172 2.42185 4.93913 2.00043 6 2.00043H18C19.0609 2.00043 20.0783 2.42185 20.8284 3.172C21.5786 3.92215 22 4.93956 22 6.00043V18.0004C22 19.0613 21.5786 20.0787 20.8284 20.8289C20.0783 21.579 19.0609 22.0004 18 22.0004H6C4.93913 22.0004 3.92172 21.579 3.17157 20.8289C2.42143 20.0787 2 19.0613 2 18.0004V6.00043ZM6 4.00043C5.46957 4.00043 4.96086 4.21114 4.58579 4.58621C4.21071 4.96129 4 5.46999 4 6.00043V18.0004C4 18.5309 4.21071 19.0396 4.58579 19.4146C4.96086 19.7897 5.46957 20.0004 6 20.0004H18C18.5304 20.0004 19.0391 19.7897 19.4142 19.4146C19.7893 19.0396 20 18.5309 20 18.0004V6.00043C20 5.46999 19.7893 4.96129 19.4142 4.58621C19.0391 4.21114 18.5304 4.00043 18 4.00043H6ZM12 9.00043C11.2044 9.00043 10.4413 9.3165 9.87868 9.87911C9.31607 10.4417 9 11.2048 9 12.0004C9 12.7961 9.31607 13.5591 9.87868 14.1217C10.4413 14.6844 11.2044 15.0004 12 15.0004C12.7956 15.0004 13.5587 14.6844 14.1213 14.1217C14.6839 13.5591 15 12.7961 15 12.0004C15 11.2048 14.6839 10.4417 14.1213 9.87911C13.5587 9.3165 12.7956 9.00043 12 9.00043ZM7 12.0004C7 10.6743 7.52678 9.40258 8.46447 8.46489C9.40215 7.52721 10.6739 7.00043 12 7.00043C13.3261 7.00043 14.5979 7.52721 15.5355 8.46489C16.4732 9.40258 17 10.6743 17 12.0004C17 13.3265 16.4732 14.5983 15.5355 15.536C14.5979 16.4736 13.3261 17.0004 12 17.0004C10.6739 17.0004 9.40215 16.4736 8.46447 15.536C7.52678 14.5983 7 13.3265 7 12.0004ZM17.5 8.00043C17.8978 8.00043 18.2794 7.84239 18.5607 7.56109C18.842 7.27978 19 6.89825 19 6.50043C19 6.1026 18.842 5.72107 18.5607 5.43977C18.2794 5.15846 17.8978 5.00043 17.5 5.00043C17.1022 5.00043 16.7206 5.15846 16.4393 5.43977C16.158 5.72107 16 6.1026 16 6.50043C16 6.89825 16.158 7.27978 16.4393 7.56109C16.7206 7.84239 17.1022 8.00043 17.5 8.00043Z"
          fill="#F1FAEE"
        />
      </svg>
    </a>
  );
};

export default FacebookIcon;
