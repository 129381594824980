import React from 'react'

export default function Unauthorized() {
    return (
        <div>
            <h1>Unauthorized</h1>
            <h3>Please <a href="/login">login</a> to access your dashboard</h3>
        </div>
    )
}
