import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BlogPageData from './BlogPageData';

export default function BlogPage() {
  const [data, setData] = useState();
  const [, setLoading] = useState(true)

  const path = window.location.pathname;

  const params = path.slice(6).replace(RegExp('%20', 'g'), ' ');
  // console.log('params: ', params);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/blog${path}`)
      .then((res) => {
        // console.log('Post response: ', res.data.post);
        setData(res.data.post);
        setLoading(false)
      });
  }, [params, path]);

  // console.log('Blog Data: ', data)

  return (
    <div>
      <BlogPageData data={data} loading={false} />
    </div>
  )
}
