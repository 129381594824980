import React from 'react'
import BlogData from './BlogData'

export default function Blog(props) {

    const data = props

    console.log('blog props: ', data)

    return (
        <div>
            <BlogData />
        </div>
    )
}
