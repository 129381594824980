import emailjs from '@emailjs/browser';
// init("user_uscJE2OKi8jiqjr9ITiY4");
import {
  questionnaireSuccess,
  questionnaireFail,
} from '../alerts/questionnaireAlert';

export const sendEmail = async ({
  fName,
  lName,
  email,
  questionnaire_result,
  location
}) => {
  await emailjs
    .send(
      'default_service',
      'template_e11l7rp',
      {
        fName: fName,
        lName: lName,
        email: email,
        questionnaire_result: questionnaire_result,
        to_name: 'Jake',
      },
      'user_uscJE2OKi8jiqjr9ITiY4'
    )
    .then((res) => {
      if (res.status !== 200) {
        questionnaireFail();
      } else {
        questionnaireSuccess();
        window.location.href=`${location}`
      }
    })
    .catch((err) => {
      console.log('NEWS FAILED HERE', err);
    });
};
