import React from "react";

export default function Terms() {
  return (
    <div className="terms">
      <div className="wrapper">
        <h1>Privacy Policy</h1>
        <h3 className="update">Updated: 11/23/2021</h3>
        <p>
          Thank you for choosing to be part of our community at Cake Nutrition,
          LLC <b>('Company', 'we', 'us', 'our')</b>. We are committed to
          protecting your personal information and your right to privacy. If you
          have any questions or concers about this privacy notice or our
          practices with regard to your personal information, please contact us
          at info@evidencednutrition.com
        </p>
        <br />
        <p>
          This privacy notice describes how we might use your information if
          you:
        </p>
        <br />
        <ul>
          <li>Visit our website</li>
          <li>
            Engage with us in other related ways - including any sales,
            marketing, or events.
          </li>
        </ul>
        <br />
        <p>In this privacy notice, if we refer to:</p>
        <ul>
          <li>
            <b>'Website',</b> we are referring to any website of ours that
            references or links to this policy.
          </li>
          <li>
            <b>'Services',</b> we are referring to our Website, and other
            related services, including any sales, marketing, or events.
          </li>
        </ul>
        <br />
        <p>
          The purpose of this privacy notice is to explain to you in the
          clearest way possible what information we collect, how we use it, and
          what rights you have in relation to it. If there are any terms in this
          privacy notice that you do not agree with, please discontinue use of
          our Services immediately.
        </p>
        <br />
        <p>
          <b>Please read the following notice in its entirety</b>
        </p>
        <h2>Table of Contents</h2>
        <ul>
          <li>
            <a href="#sec1">WHAT INFORMATION DO WE COLLECT?</a>
          </li>
          <li>
            <a href="#sec2">HOW DO WE USE YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#sec3">
              WILL YOUR INFORMATION BE SHARED WITH ANYONE ELSE?
            </a>
          </li>
          <li>
            <a href="#sec4">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a href="#sec5">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
          </li>
          <li>
            <a href="#sec6">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
          </li>
          <li>
            <a href="#sec7">DO WE COLLECT INFORMATION FROM MINORS?</a>
          </li>
          <li>
            <a href="#sec8">WHAT ARE YOUR PRIVACY RIGHTS?</a>
          </li>
          <li>
            <a href="#sec9">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
          </li>
          <li>
            <a href="#sec10">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#sec11">DO WE MAKE UPDATES TO THIS NOTICE?</a>
          </li>
          <li>
            <a href="#sec12">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
          </li>
          <li>
            <a href="#sec13">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </a>
          </li>
        </ul>
        <br />
        <h2 id="sec1">1. WHAT INFORMATION DO WE COLLECT?</h2>
        <h3>Personal information you disclose to us</h3>
        <p>
          We collect personal information that you voluntarily provide to us
          when you express interest in obtaining information about us or our
          products and services, when you contact us, or otherwise when you
          voluntarily interact with our website.
        </p>
        <br />
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Website, the choices you make when
          navigating the website, and other general behaviors within the scope
          of our website.
        </p>
        <br />
        <p>
          <b>Personal information provided by you: </b> we collect names, phone
          numbers, email addresses mailing addresses, contact preferences or
          authentication data, billing addresses, debit/credit card information,
          and other identification information.
        </p>
        <br />
        <p>
          <b>Payment Data: </b>we may collect data necessary to process your
          payment if you make purchases, such as your credit/debit card
          information, and the relevant security information for authentication
          purposes. All data is stored and protected by Stripe(TM), and you can
          review their privacy policy{" "}
          <a href="https://stripe.com/privacy">here.</a>
        </p>
        <br />
        <p>
          All personal information that you provide must be true, complete, and
          accurate and you must notify us of any changes to such personal
          information.
        </p>
        <br />
        <p>
          <b>Fraud Prevention</b> Information provided by users that does not
          belong to them, used for fraudulent activities, or to defraud us, our
          partners, our other individuals will be reported to the relevant
          authorities at local, state, and/or federal levels. Should information
          provided with the intent to defraud be owned by authentic individuals;
          all relevant information surrounding fraudulent activity or
          transactions will be provided to the owner of the information and or
          parties on their behalf.
        </p>
        <h3>Information automatically collected</h3>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Website. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and data usage information, such as your IP address, browser
          and device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Website and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Website, and for our internal analytics and reporting purposes.
          User data is critical to improving the security of our Website, and
          detecting fraudulent or malicious activity, so as to avoid visitors,
          users, and customers from being at risk.
        </p>
        <br />
        <p>
          Like many businesses, we also collect information through cookies and
          other similar technology.
        </p>
        <br />
        <p>The information we collect includes:</p>
        <ul>
          <li>
            
              <i>Log and Usage Data</i>: Log and usage data is service-related,
              diagnostic, usage and performance information our servers
              automatically collect when you access or use our Websute and which
              we record in log files. Depending on how you interact with us,
              this log data may include your IP address, device information,
              browser type and settings and information about your activity in
              the Website (such as the date/time stamps associated with your
              usage, pages and files viewed, searches and other actions you take
              such as which features you use), device event information (such as
              system activity, error reports (sometimes called 'crash dumps')
              and hardware settings).
            
          </li>
          <li>
            
              <em>Device Data.</em> We collect device data such as information
              about your computer, phone, tablet or other device you use to
              access the Website. . Depending on the device used, this device
              data may include information such as your IP address (or proxy
              server), device and application identification numbers, location,
              browser type, hardware model Internet service provider and/or
              mobile carrier, operating system and system configuration
              information.
            
          </li>
          <li>
            
              <em>Location Data.</em> We collect location data such as
              information about your device's location, which can be either
              precise or imprecise. How much information we collect depends on
              the type and settings of the device you use to access the . For
              example, we may use GPS and other technologies to collect
              geolocation data that tells us your current location (based on
              your IP address). You can opt out of allowing us to collect this
              information either by refusing access to the information or by
              disabling your Location setting on your device. Note however, if
              you choose to opt out, you may not be able to use certain aspects
              of the Services.
            
          </li>
        </ul>
        <h2 id="sec2">2. HOW DO WE USE YOUR INFORMATION?</h2>
        <p>
          We use personal information collected via our Website for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <br />
        <p>We use the information we collect or receive:</p>
        <br />
        <ul>
          <li>
            
              <strong>To facilitate account creation and logon process.</strong>
              If you choose to link your account with us to a third-party
              account (such as your Google or Facebook account), we use the
              information you allowed us to collect from those third parties to
              facilitate account creation and logon process for the performance
              of the contract.
            
          </li>
          <li>
            
              <strong>To post testimonials.</strong> We post testimonials on our
              Website that may contain personal information. Prior to posting a
              testimonial, we will obtain your consent to use your name and the
              content of the testimonial. If you wish to update, or delete your
              testimonial, please contact us at and be sure to include your
              name, testimonial location, and contact information.
            
          </li>
        
            <li>
              <strong>Request feedback</strong> We may use your information to
              request feedback and to contact you about your use of our Website.
            </li>
            <li>
              <strong>To enable user-to-user communicatoins.</strong> We may use
              your information in order to enable user-to-user communication
              with each user's consent.
            </li>
            <li>
              <strong>To manage user accounts.</strong> We may use yoru
              information for the purposes of managing our account and keeping
              it in working order.
            </li>
            <li>
              <strong>To send administrative information to you.</strong> We may
              use your personal information to send you product, service, and
              new feature information and/or information about changes to our
              terms, conditions, and policies.
            </li>
            <li>
              <strong>To protect our Services.</strong> We may use your
              information as part of our efforts to keep our Websute safe and
              secure (for example, for fraud monitoring and prevenetion).
            </li>
            <li>
              <strong>
                To enfornce our terms, conditions, and policies for business
                purposes, to comply with legal and regulatory requirements or in
                connection with our contract.
              </strong>
            </li>
            <li>
              <strong>To respond to legal request and prevent harm.</strong> If
              we receive a subpoena or other legal requests, we may need to
              inspect the data we hold to determine how to respond.
            </li>
            <li>
              <strong>Fulfill and manage your orders.</strong> We may use your
              information to fulfill and manage your orders, payments, returns,
              and exchanges made through the Website.
            </li>
            <li>
              <strong>Administer prize draws and competitions.</strong> We may
              use your information to administer prize draws and competitions
              when you elect to participate in our competitions.
            </li>
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user.
              </strong>{" "}
              We may use your information to provide you with the requested
              service.
            </li>
            <li>
              <strong>
                To respond to user inquiries/offer support to users.
              </strong>{" "}
              We may use your information to respond to your inquiries and solve
              any potential issues you might have with the use of our Services.
            </li>
            <li>
              <strong>
                To send you marketing and promotional communications.
              </strong>{" "}
              We and/or our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the WHAT ARE YOUR PRIVACY RIGHTS
              section).
            </li>
            <li>
              <strong>Deliver targeted advertising to you.</strong> We may use
              your information to develop and display personalized content and
              advertising (and work with third parties who do so) tailored to
              your interests and/or location and to measure its effectiveness.
            </li>
          
        </ul>

        <h2 id="sec3">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE ELSE?</h2>
        <p>
          We may process or share your data that we hold based on the following{" "}
          <strong>legal basis</strong>:
        </p>
        
          <ul>
            <li>
              <strong>Consent:</strong> We may process your data if you have
              given us specific consent to use your personal information for a
              specific purpose.
            </li>
            <li>
              <strong>Legitimate Interests:</strong> We may process your data
              when it is reasonably necessary to achieve our legitimate business
              interests.
            </li>
            <li>
              <strong>Performance of a Contract:</strong> Where we have entered
              into a contract with you, we may process your personal information
              to fulfill the terms of our contract.
            </li>
            <li>
              <strong>Legal Obligations:</strong> We may disclose your
              information where we are legally required to do so in order to
              comply with applicable law, governmental requests, a judicial
              proceeding, court order, or legal process, such as in response to
              a court order or a subpoena (including in response to public
              authorities to meet national security or law enforcement
              requirements).
            </li>
            <li>
              <strong>Vital Interests:</strong> We may disclose your information
              where we believe it is necessary to investigate, prevent, or take
              action regarding potential violations of our policies, suspected
              fraud, situations involving potential threats to the safety of any
              person and illegal activities, or as evidence in litigation in
              which we are involved.
            </li>
            
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            
            <ul>
              <li>
                <strong>Business Transfers.</strong> We may share or transfer
                your information in connection with, or during negotiations of,
                any merger, sale of company assets, financing, or acquisition of
                all or a portion of our business to another company.
              </li>
            </ul>
          </ul>
        
        <h2 id="sec4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice
        </p>
        <h2 id="sec5">5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this notice
          will require us keeping your personal information for longer than{" "}
        </p>
        <br />
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h2 id="sec6">6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, despite our safeguards and efforts to secure your
          information, no electronic transmission over the Internet or
          information storage technology can be guaranteed to be 100% secure, so
          we cannot promise or guarantee that hackers, cybercriminals, or other
          unauthorized third parties will not be able to defeat our security,
          and improperly collect, access, steal, or modify your information.
          Although we will do our best to protect your personal information,
          transmission of personal information to and from our Website is at
          your own risk. You should only access the Website within a secure
          environment.
        </p>
        <h2 id="sec7">7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Website, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Website. If we learn that
          personal information from users less than 18 years of age has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such data from our records. If you become aware of
          any data we may have collected from children under age 18, please
          contact us at matt@leyline.dev.
        </p>
        <h2 id="sec8">8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
        <p>
          If you are a resident in the EEA or UK and you believe we are
          unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details here:{" "}
          <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </a>
        </p>
        <br />
        <p>
          If you are a resident in Switzerland, the contact details for the data
          protection authorities are available here:{" "}
          <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
            https://www.edoeb.admin.ch/edoeb/en/home.html
          </a>
        </p>
        <br />
        <p>
          <u>Cookies and similar technologies:</u> Most Web browsers are set to
          accept cookies by default. If you prefer, you can usually choose to
          set your browser to remove cookies and to reject cookies. If you
          choose to remove cookies or reject cookies, this could affect certain
          features or services of our Website. To opt-out of interest-based
          advertising by advertisers on our Website visit{" "}
          <a href="http://www.aboutads.info/choices">
            http://www.aboutads.info/choices
          </a>
        </p>
        <h2 id="sec9">9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h2 id="sec10">
          10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h2>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <br />
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Website, , you have the right to request
          removal of unwanted data that you publicly post on the Website. . To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Website, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g. backups, etc.).
        </p>
        <br />
        <h3>CCPA Privacy Notice</h3>
        <p>The California Code of Regulations defines a "resident" as:</p>
        <ul>
          
            <li>
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </li>
            <li>
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </li>
          
        </ul>
        <p>All other individuals are defined as "non-residents."</p>
        <br />
        <p>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>
        <br />
        <p>
          <strong>
            What categories of personal information do we collect?
          </strong>
        </p>
        <br />
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <div className="chart">
          <div className="row title">
            <div className="col-1">Category</div>
            <div className="col-2">Examples</div>
            <div className="col-3">Collected</div>
          </div>
          <div className="row">
            <div className="col-1">A. Identifiers</div>
            <div className="col-2">
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address and
              account name
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">
              B. Personal information categories listed in the California
              Customer Records statute
            </div>
            <div className="col-2">
              Name, contact information, education, employment, employment
              history and financial information
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">
              C. Protected classification characteristics under California or
              federal law
            </div>
            <div className="col-2">Gender and date of birth</div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">D. Commercial information</div>
            <div className="col-2">
              Transaction information, purchase history, financial details and
              payment information
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">E. Biometric information</div>
            <div className="col-2">Fingerprints and voiceprints</div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">
              F. Internet or other similar network activity
            </div>
            <div className="col-2">
              Browsing history, search history, online behavior, interest data,
              and interactions with our and other websites, applications,
              systems and advertisements
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">G. Geolocation data</div>
            <div className="col-2">Device Location</div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">
              H. Audio, electronic, visual, thermal, olfactory, or similar
              information
            </div>
            <div className="col-2">
              Images and audio, video or call recordings created in connection
              with our business activities
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">
              I. Professional or employment-related information
            </div>
            <div className="col-2">
              Business contact details in order to provide you our services at a
              business level, job title as well as work history and professional
              qualifications if you apply for a job with us{" "}
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">J. Education information</div>
            <div className="col-2">
              Student records and directory information
            </div>
            <div className="col-3">NO</div>
          </div>
          <div className="row">
            <div className="col-1">
              K. Inferences drawn from other personal information
            </div>
            <div className="col-2">
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </div>
            <div className="col-3">NO</div>
          </div>
        </div>
        <br />
        <p>
          We may also collect other personal information outside of these
          categories instances where you interact with us in-person, online, or
          by phone or mail in the context of:
        </p>
        <ul>
          
            <li>Receiving help through our customer support channels.</li>
            <li>Participation in customer surveys or contests; and</li>
            <li>
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </li>
          
        </ul>
        <p>
          <strong>How do we use and share your personal information?</strong>
        </p>
        <br />
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice
        </p>
        <br />
        <p>
          You may contact us by visiting{" "}
          <a href="http://www.evidencednutrition.com/ccpa">
            http://www.evidencednutrition.com/ccpa
          </a>{" "}
          or by referring to the contact details at the bottom of this document.
        </p>
        <br />
        <p>
          If you are using an authorized agent to exercise your right to opt-out
          we may deny a request if the authorized agent does not submit proof
          that they have been validly authorized to act on your behalf.
        </p>
        <p>
          <strong>Will your information be shared with anyone else?</strong>
        </p>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf.
        </p>
        <br />
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be "selling"
          of your personal data.
        </p>
        <br />
        <p>
          Cake Nutrition, LLC has not disclosed or sold any personal information
          to third parties for a business or commercial purpose in the preceding
          12 months. <br /> <br />
          Cake Nutrition, LLC will not sell personal information in the future
          belonging to website visitors, users and other consumers.
        </p>
        <h3>Your rights with respect to your personal data</h3>
        <br />
        <p>
          <u>Right to request deletion of the data - Request to delete</u>
        </p>
        <br />
        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation or any processing that
          may be required to protect against illegal activities.
        </p>
        <p>
          <u>Right to be informed - Request to know</u>
          Depending on the circumstances, you have a right to know:
        </p>
        
          <ul>
            <li>whether we collect and use your personal information</li>
            <li>the categories of personal information that we collect</li>
            <li>
              the purposes for which the collected personal information is used.
            </li>
            <li>whether we sell your personal information to third parites.</li>
            <li>
              the categories of personal information that we sold or disclosed
              for a business purpose.
            </li>
            <li>
              the categories of third parties to whom the personal information
              was sold or disclosed for a business purpose; and
            </li>
            <li>
              the business or commercial purpose for collecting or selling
              personal information.
            </li>
          </ul>
        
        <br />
        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
        <br />
        <p>
          <u>
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
            Rights
          </u>
        </p>
        <br />
        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
        <br />
        <p>
          <u>Verification process</u>
        </p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication method
          (e.g. phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>
        <br />
        <p>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. If, however, we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity, and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>
        <p>
          <u>Other privacy rights</u>
        </p>
        <br />
        
          <ul>
            <li>you may object to the processing of your personal data.</li>
            <li>
              you may request correction of your personal data if it is
              incorrect or no longer relevant, or ask to restrict the processing
              of the data.
            </li>
            <li>
              you can designate an authorized agent to make a request under the
              CCPA on your behalf. We may deny a request from an authorized
              agent that does not submit proof that they have been validly
              authorized to act on your behalf in accordance with the CCPA.
            </li>
            <li>
              you may request to opt-out from future selling of your personal
              information to third parties. Upon receiving a request to opt-out,
              we will act upon the request as soon as feasibly possible, but no
              later than 15 days from the date of the request submission.
            </li>
          </ul>
        
        <br />
        <p>
          To exoercise these rights you can contact us by visiting{" "}
          <a href="http://wwww.evidencednutrition.com/ccpa">
            http://wwww.evidencednutrition.com/ccpa
          </a>{" "}
          or by referring to the contact details at the bottom of this document.
          If you have a complaint about how we handle your data, we would like
          to hear from you.
        </p>
        <br />
        <p>
          <strong>Financial Incentives</strong>
        </p>
        <br />
        <p>
          "Financial incentive" means a program, benefit, or other offering,
          including payments to consumers as compensation, for the disclosure,
          deletion, or sale of personal information.
        </p>
        <br />
        <p>
          The law permits financial incentives or a price or service difference
          if it is reasonably related to the value of the consumer’s data. A
          business must be able to explain how the financial incentive or price
          or service difference is reasonably related to the value of the
          consumer’s data. The explanation must include:
        </p>
        <br />
        
          <ul>
            <li>
              a good-faith estimate of the value of the consumer’s data that
              forms the basis for offering the financial incentive or price or
              service difference; and
            </li>
            <li>
              a description of the method the business used to calculate the
              value of the consumer’s data.
            </li>
          </ul>
        
        <br />
        <p>
          We may decide to offer a financial incentive (e.g. price or service
          difference) in exchange for the retention or sale of a consumer’s
          personal information.
        </p>
        <br />
        <p>
          If we decide to offer a financial incentive, we will notify you of
          such financial incentive and explain the price difference, as well as
          material terms of the financial incentive or price of service
          difference, including the categories of personal information that are
          implicated by the financial incentive or price or service difference.
        </p>
        <br />
        <p>
          If you choose to participate in the financial incentive you can
          withdraw from the financial incentive at any time by visiting{" "}
          <a href="http://www.evidencednutrition.com/ccpa">
            http://www.evidencednutrition.com/ccpa
          </a>{" "}
          or by referring to the contact details at the bottom of this document.
        </p>
        <h2 id="sec11">11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <h2 id="sec12">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
        <p>
          If you have questions or comments about this notice, you may contact
          our Data Protection Officer (DPO), Matthew Bergeron, by email at
          matt@leyline.dev. If you have any further questions or comments, you
          may also contact us by post at the following corporate address:
        </p>
        <br />
        <div className="mailAddress">
          <p>Cake Nutrition, LLC</p>
          <p>700 West 97 St. Clair Ave.</p>
          <p>Suite 110</p>
          <p>Cleveland, OH 44113</p>
        </div>
        <h2 id="sec13">
          13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
          YOU?
        </h2>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it in some circumstances. To
          request to review, update, or delete your personal information, please
          submit a request form by clicking{" "}
          <a href="https://app.termly.io/notify/d8940648-1cb2-4d2d-87fd-b6ba338db006">
            here
          </a>
        </p>
      </div>
    </div>
  );
}
