import React, { useState } from "react";
import Logo from "../utils/Logo";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import LandingPage from "./LandingPage";
import About from "./About";
import Contact from "./Contact";
import Services from "./Services";
import MetabolicConsultation from "./MetabolicConsultation";
import LifestyleCounseling from "./LifestyleCounseling";
import AdvancedPackage from "./AdvancedPackage";
import PremierPackage from "./PremierPackage";
import Curriculum from "./Curriculum";
import Consult from "./RequestButton";
import RDCTA from "./RDCTA";
import Corporate from "./Corporate";
import ReleaseForm from "../utils/ReleaseForm";
import PaymentForm from "./PaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PrivacyPolicy from "./PrivacyPolicy";
import Terms from "./Terms";
import Popup from "./Popup";
import Blog from './blog/Blog'
import Dashboard from "./user/Dashboard";
import PrivateRoute  from "../utils/auth/PrivateRoute";
import Login from "./user/Login";
import Unauthorized from "./Unauthorized";
import PassReset from "./user/PassReset";
import BlogPage from './blog/BlogPage'

// const PUBLIC_KEY =
//   "pk_test_51IR3ItGoEhcHp2VRDjKKBdzHKCAGqsRF6Ki4mIW9HzK7TOIUAPDowkt73UFTRLmQIXQxmLTyeBeKir9iBZ9GpSsy00qLITBnqK";

const stripeTestPromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

export default function Nav() {
  const [trigger, setTrigger] = useState(false);

  return (
    <div className="nav">
      <div className="wrapper">
        <Popup trigger={trigger} setTrigger={setTrigger} />
        <Router className="router">
          <div className="upperbar">
            <Link className="request-button" to={"/request_consult"}>
              Request a Consult
            </Link>
          </div>
          <div className="navbar">
            <Logo />
            <div className="link-container">
              <Link className="link tablet" to={"/"}>
                Home
              </Link>
              <div id="dropdown-trigger" className="link tablet">
                Services
                <div className="dropdown">
                  <Link className="link tablet" to={"/lifestyle_counseling"}>
                    Lifestyle Counseling
                  </Link>
                  <Link className="link tablet" to={"/metabolic_consultation"}>
                    Metabolic Consultation
                  </Link>
                  <Link className="link tablet" to={"/advanced_package"}>
                    Advanced Package
                  </Link>
                  <Link className="link tablet" to={"/premier_package"}>
                    Premier Package
                  </Link>
                  <Link className="link tablet" to={"/request_consult"}>
                    Request a Consult
                  </Link>
                  <Link className="link tablet" to={"/services"}>
                    All Services
                  </Link>
                </div>
              </div>
              <Link className="link tablet" to={"/about"}>
                About
              </Link>
              <Link className='link tablet' to={'/blog'}>Blog</Link>
              <Link className="link tablet" to={"/contact"}>
                Contact
              </Link>
              <a
                className="link tablet"
                href="https://www.thesciencesays.com/l/precision-nutrition-metabolic-health/60e319da-98e3-4ba4-849e-ef0a8940eb8c"
              >
                TheScienceSays.com
              </a>
            </div>
          </div>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/about" component={About} />
            <Route path="/blog" component={Blog} />
            <Route path="/post/:title" component={BlogPage} />
            <Route path="/contact" component={Contact} />
            <Route path="/services" component={Services} />
            <Route path="/request_consult" component={Consult} />
            <Route
              path="/metabolic_consultation"
              component={MetabolicConsultation}
            />
            <Route
              path="/lifestyle_counseling"
              component={LifestyleCounseling}
            />
            <Route path="/advanced_package" component={AdvancedPackage} />
            <Route path="/premier_package" component={PremierPackage} />
            <Route path="/curriculum_vitae" component={Curriculum} />
            <Route path="/why_choose_a_RD" component={RDCTA} />
            <Route path="/corporate_wellness" component={Corporate} />
            <Route path="/release_form" component={ReleaseForm} />
            <Route path="/privacy_policy" component={PrivacyPolicy} />
            <Route path="/terms_and_conditions" component={Terms} />
            <Route path="/unauthorized" component={Unauthorized} />
            <Route path="/login" component={Login} />
            <Route path="/reset-password" component={PassReset} />

            {/* auth only routes */}
            <PrivateRoute path='/dashboard' component={Dashboard}/>

            {/* end of auth only routes */}
            <Elements stripe={stripeTestPromise}>
              <Route path="/checkout" component={PaymentForm} />
            </Elements>
          </Switch>
        </Router>
      </div>
    </div>
  );
}
