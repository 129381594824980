import Swal from 'sweetalert2';

export const paymentSuccess = async () => {
  await Swal.fire({
    title: 'Payment Submitted!',
    timer: 5000,
    text: "Thank you for your payment! We will be contacting you to follow up within 24-48 hours.",
    icon: 'success',
  }).then(() => {
    window.location.href = "/";
  });
};

export const paymentFail = async () => {
    await Swal.fire({
      title: 'Error: Could not submit payment',
      text: "Sorry, but we could not process your payment. Please try again later.",
      timer: 5000,
      icon: 'error',
    }).then(() => {
      window.location.reload();
    });
  };