import React from 'react'
import { BlogForm } from './elements/blog_utils/BlogForm'

export default function Dashboard() {

    return (
        <div>
            <h1>Dashboard</h1>
            <BlogForm />
        </div >
    )
}
