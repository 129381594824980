import React, { useState, useEffect } from "react";
import { sendEmail } from "../utils/sendEmail";

export default function RDCTA() {
  const [visitor, setVisitor] = useState({
    fName: "",
    lName: "",
    email: "",
  });

  useEffect(() => {
    localStorage.setItem("visitor_email", visitor.email);
    localStorage.setItem("visitor_fName", visitor.fName);
    localStorage.setItem("visitor_lName", visitor.lName);
  }, [visitor.email, visitor.fName, visitor.lName]);

  const handleChange = (e) => {
    setVisitor({
      ...visitor,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="RDCTA">
      <div className="wrapper">
        <h1>What Makes a Registered Dietitian Different?</h1>
        <div className="content">
          <div className="description">
            <p>
              Registered Dietitians (RDs) are food and nutrition experts who
              have met extensive and intensive education, training, and testing
              in order to receive their Credentials*:
            </p>
            <ul>
              <li>
                <b>Accredited Degree:</b> the bare minimum to even be allowed to
                start the process of becoming a RD is to attain a Bachelor's
                Degree from a US or Internationally recognized and accredited
                University or College with course work that is approved by the
                Accredidation Council for Education in Nutrition and Dietetics
                (ACEND) of the Academy of Nutrition and Dietetics.
              </li>
              <li>
                <b>Pracitcal Training: </b> once a degree has been attained, an
                aspiring RD must complete an ACEND-accredited supervised
                practice program at a health-care facility, community agency, or
                a foodservice organization, or a combination of undergraduate or
                graduate studies that fulfill these practice standards within
                the degree requirements. A typical practice program will take 6
                months to a year to complete.
              </li>
              <li>
                <b>Pass the National Examination: </b> once the degree and
                training has been complete, a RD in training may take an
                examination administered by the Commission on Dietetic
                Registration (CDR). Only when an RD has passed this exam, will
                they receive their credentials.
              </li>
              <li>
                <b>Continuing Education: </b> a RD must maintain professional
                education requirements before their certifications expire. A RD
                is never not learning, not only to improve themselves, but to
                maintain the integrity of the practice, and thus, maintaining
                the safety and health of the clients they work with.
              </li>
            </ul>
            <br />
            <p>
              Some RD's hold additional certifications and training in
              specialized areas of practice, which are also awarded through the
              CDR. Some of these specializations include pediatric or renal
              nutrition, sports dietetics, and a whole host of other
              specialization focusing on different aspects of health and
              society. Many RDs also hold other advanced degrees, such as PhDs
              in Nutritional Science, or even Medical Degrees.
              <br />
              <br />
              In addition, many states within the United States have regulatory
              laws concerning dietary and nutritional advice. This may not only
              deem who is allowed to legally practice within a certain state,
              but also maintain certain standards of practice and patient care.
              RDs are also required to follow regulatory legislature, such as
              HIPPA.
              <br /> <br />
              While there may be many options for diet and nutrition on the
              market, only an Registered Dietitian has the extensive education,
              and hands on training, to provide the most up to date and safe
              information.
            </p>

            <p
              className="reference"
              style={{ fontSize: "10px", marginTop: "5%" }}
            >
              *This information is paraphrased from the Academy of Nutirition
              and Dietetics. For more information on accredidation and licensing
              requirements, please visit{" "}
              <a href="https://www.eatrightpro.org/about-us/what-is-an-rdn-and-dtr/what-is-a-registered-dietitian-nutritionist">
                the Academy of Nutrition and Dietetics
              </a>
              official website.
            </p>
          </div>
        </div>
        <div className="email-form">
          <h2>Questions? Send them here!</h2>
          <form
            className="input-form"
            onSubmit={(e) => {
              e.preventDefault();
              sendEmail(visitor.fName, visitor.lName, visitor.email);
            }}
          >
            <input
              required
              type="text"
              name="fName"
              value={visitor.fName}
              placeholder="FIRST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              type="text"
              name="lName"
              value={visitor.lName}
              placeholder="LAST NAME"
              onChange={handleChange}
            />
            <hr className="vert-divider-blue" />
            <input
              required
              className="cta_email"
              type="email"
              name="email"
              value={visitor.email}
              placeholder="EMAIL"
              onChange={handleChange}
            />
            <button className="button" type="submit">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
